/**
 * 解决方案-区块链电子证照
 */
import React, { Component } from "react";
import "./blockchainLicense.scss";
import CommonSolution from "../../components/commonSolution";
import operationFlowPNG from "../../assets/images/solution/blockchainLicenseOperationFlow.png";
import boxCom1PNG from '../../assets/images/andChain/first.png';
import boxCom2PNG from '../../assets/images/andChain/second.png';
import boxCom3PNG from '../../assets/images/andChain/third.png';

export default class BlockchainLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                header: {
                    name: '区块链电子证照',
                    describe: '支持权限管理体系下的数据共享，提升多部门业务协作效率',
                    buttonName: '业务咨询'
                },
                schemeIntroduction: {
                    name: '方案介绍',
                    describe: '区块链不可篡改、分布式网络和存储、加密与共识算法保证可信安全等特征，有利于应用到电子证照共享管理建设中。通过建设基于区块链的电子证照共享平台，能够实现海量数据的安全存储和查询，以及跨地区和跨部门的可信数据共享。区块链融入电子证照管理中对政务改革优化起到的作用包括：明确落实数据管理与有效性权责、解决数据安全与数据共享的矛盾、构建部门间数据共享激励机制等。'
                },
                operationFlow: {
                    name: '业务流程',
                    img: operationFlowPNG
                },
                businessValue: {
                    name: '业务价值',
                    titleHeight: '1.4rem',
                    showBoxComData: [
                        { name: '明确数据权责', image: boxCom1PNG, content: '电子证照数据的上传、查询、共享操作全程留痕，数据权属关系明确。' },
                        { name: '保障隐私安全', image: boxCom2PNG, content: '成熟的密码学体系保障数据的加密传输和权限管理，只有经过授权才能查看明文数据。' },
                        { name: '提升业务效率', image: boxCom3PNG, content: '改变部门间信息闭塞的痛点，促进信息共享，实现业务线上办理，极大地提升业务效率。' }
                    ]
                },
                applicationCases: {
                    name: '应用案例',
                    subName: '电子证照共享平台',
                    partnerTitle: '合作方',
                    partnerText: '',
                    caseIntroductionTitle: '案例介绍',
                    caseIntroductionText: '基于区块链的电子政务共享平台，项目建设遵循电子证照相关国家标准，满足互联网+政务服务技术体系相关标准，保证电子证照的合规性。平台具有完善的电子证照、电子证照目录体系的灵活调度和管理能力，兼容各个政务部门的业务规范，支持证照核验、证照查询、证照提交和注销等服务能力。\n\n区块链底层提供高效的接口服务模块，可与其他异构电子证照共享平台实现跨部门、跨地区、跨领域的电子证照数据安全共享，方便各个业务部门信息系统接入。具备完善的权限管理体系，按照权限分层应用电子证照数据，有效防止越权操作行为。具备数据完整和保密机制、身份认证与服务资源的双向认证机制和数据安全机制。'
                }
            }
        };
    }

    headerOnClick =() => {
        this.props.history.push({ pathname: '/programAns/blockchainLicense' });
    };

    render() {
        let { data } = this.state;
        return (
            <div className="blockchainLicense">
                <CommonSolution headerOnClick={this.headerOnClick} data={data} />
            </div>
        );
    }
}
