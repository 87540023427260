import React, {Component} from 'react'
import {Tabs, Collapse, Button} from "antd";
import dark1PNG from '../../../assets/images/joinus/dark1.png'
import dark2PNG from '../../../assets/images/joinus/dark2.png'
import light1PNG from '../../../assets/images/joinus/light1.png'
import light2PNG from '../../../assets/images/joinus/light2.png'
import addPNG from '../../../assets/images/joinus/add.png'
import plusPNG from '../../../assets/images/joinus/plus.png'
import whitePlusPNG from '../../../assets/images/joinus/whitePlus.png'
import {isMobile} from '../../../utils/util';

const {TabPane} = Tabs;
const {Panel} = Collapse;

const zhaopinData = [
    {
        jobName: '区块链市场实习生（南京）',
        jobUrl: 'https://www.zhipin.com/job_detail/7b6c630be2a0020e1nR90t-8FVpZ.html?ka=comp_joblist_1',
        responsibility: ['职位描述：', '1.开展行业调研、竞品调研、情报调研工作，发掘商业机会，为公司营销活动提供有力支持;',
            '2.参与新媒体运营、媒体关系维护以及SEO等工作;',
            '3.参与各类展会、会议、竞赛活动的策划与实施;',
            '4.参与公司市场发展战略的制订、产品定位及品牌建设工作。',
            '相关要求：',
            '1.对区块链、大数据领域有一定了解，或者愿意持续学习相关行业应用知识；',
            '2.了解并认同市场工作的价值，具有优秀的团队协作精神；',
            '3.具备优秀的逻辑思维能力和语言表达能力；',
            '4.对工作抱有热情，具有强大的责任感、执行力和严格的自我要求。'
        ]
    }
];
export default class RecruitmentTabs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: '1',
            isMobile: isMobile()
        }
    }

    tabChange = (key) => {
        this.setState({tab: key});
    }

    render() {
        let {tab, isMobile} = this.state;
        return (
            <div>
                <Tabs defaultActiveKey="1" onChange={this.tabChange}>
                    <TabPane tab={<span className="tab1" style={tab === '1' ? {color: '#337DFF'} : {}}> <img
                        src={tab === '1' ? light1PNG : dark1PNG}/>社会招聘</span>} key="1">
                        <Collapse defaultActiveKey={["1"]} accordion expandIcon={({isActive}) => isActive && isMobile ?
                            <img style={{width: '25px'}} src={whitePlusPNG}/> : isActive && !isMobile ?
                                <img src={plusPNG}/> : <img src={addPNG}/>} expandIconPosition="right">
                            <Panel
                                header="前端开发工程师（南京，3~5年）"
                                key="1"
                                style={{marginBottom: isMobile ? '0.4rem' : 0}}
                            >
                                <div className="leftBox">
                                    <p>职位描述： </p>
                                    <p>
                                        1、主要负责PC端、H5、微信公众号、小程序前端开发、交互实现及性能优化工作；
                                    </p>
                                    <p>
                                        2、研究并改善产品，优化产品的用户体验；与后端工程师协作，高效完成产品的数据交互、动态数据展现。
                                    </p>
                                    <p>相关要求： </p>
                                    <p>
                                        1、掌握如下前端技术栈的多种：React、react-router、redux、mobx\AntDesign\ES6；
                                    </p>
                                    <p>
                                        2、至少一年以上React开发经验，有完整SPA单页面开发经验；
                                    </p>
                                    <p>
                                        3、掌握Javascript、CSS3、HTML5，对Javascript的语言特性有比较深入的理解，熟悉使用ES6标准；
                                    </p>
                                    <p>4、掌握前端UI框架，如ElementUI,AntDesign等，有实现复杂可复用自定义前端框架组件经验；</p>
                                    <p>
                                        5、熟练使用Webpack/Browserify等工具，熟悉前端项目工程化的流程和方法；
                                    </p>
                                    <p>6、熟悉模块化开发的思想，有良好的视觉审美品味；</p>
                                    <p>7、注重细节和用户体验。</p>
                                    <a
                                        href="https://www.zhipin.com/job_detail/5c55f683418db7290X1_3dy6F1M~.html?ka=comp_joblist_1"
                                        target="_blank"
                                    >
                                        <Button className="tab1-btn">立即申请</Button>
                                    </a>
                                </div>
                            </Panel>
                            <Panel
                                header="JAVA开发工程师（南京，3~5年）"
                                key="2"
                                style={{marginBottom: isMobile ? '0.4rem' : 0}}
                            >
                                <div className="leftBox">
                                    <p>职位描述： </p>
                                    <p>
                                        1. 与团队共同完成软件系统代码的实现，编写代码注释和开发文档；
                                    </p>
                                    <p>
                                        2. 进行系统的功能定义，程序设计；
                                    </p>
                                    <p>3. 配合项目经理完成相关任务目标。 </p>
                                    <p>相关要求： </p>
                                    <p>
                                        1. 计算机相关专业，至少两年以上工作经验；
                                    </p>
                                    <p>
                                        2. 熟悉版本管理配置工具如maven、svn、git等；
                                    </p>
                                    <p>
                                        3. 熟练掌握 Spring Cloud，Spring Boot，Spring Security，MyBatis 等框架进行项目开发；
                                    </p>
                                    <p>
                                        4. 熟悉Web分布式系统，熟悉Nginx、Tomcat、Redis等应用解决方案；
                                    </p>
                                    <p>
                                        5. 具有 RESTful API 设计和开发经验；
                                    </p>
                                    <p>6. 良好的沟通能力，责任心强，能承担较强的工作压力，富有团队精神； </p>
                                    <p>7. 熟悉MySQL，Oracle，达梦等数据库，对SQL调优有一定的实践经验等。</p>
                                    <a
                                        href="https://www.zhipin.com/job_detail/eb28bc57aa892c6a1HVz2tS4GFA~.html?ka=comp_joblist_2"
                                        target="_blank"
                                    >
                                        <Button className="tab1-btn">立即申请</Button>
                                    </a>
                                </div>
                            </Panel>
                        </Collapse>
                    </TabPane>
                    <TabPane tab={<span className="tab2" style={tab === '2' ? {color: '#337DFF'} : {}}> <img
                        src={tab === '2' ? light2PNG : dark2PNG}/>校园招聘</span>} key="2">
                        <Collapse defaultActiveKey={["0"]} accordion expandIcon={({isActive}) => isActive && isMobile ?
                            <img style={{width: '25px'}} src={whitePlusPNG}/> : isActive && !isMobile ?
                                <img src={plusPNG}/> : <img src={addPNG}/>} expandIconPosition="right">
                        {/*暂无招聘信息。*/}
                        {
                            zhaopinData.map((item, index) => {
                                return  <Panel
                                    header={item.jobName}
                                    key={index}
                                >
                                    <div className="leftBox">
                                        {
                                            item.responsibility.map((item, index) => {
                                                return <p>{item}</p>
                                            })
                                        }
                                        <a
                                            href={item.jobUrl}
                                            target="_blank"
                                        >
                                            <Button className="tab1-btn">立即申请</Button>
                                        </a>
                                    </div>
                                </Panel>
                            })
                        }
                        </Collapse>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
