/**
 * 解决方案-区块链存证
 */
import React, { Component } from "react";
import './blockchainCertificate.scss';
import CommonSolution from '../../components/commonSolution';
import boxCom1PNG from '../../assets/images/andChain/first.png';
import boxCom2PNG from '../../assets/images/andChain/second.png';
import boxCom3PNG from '../../assets/images/andChain/third.png';
import operationFlowPNG from '../../assets/images/solution/blockchainCertificateOperationFlow.png';

export default class BlockchainCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                header: {
                    name: '区块链存证',
                    describe: '区块链技术保障电子证据的可追溯和不可篡改',
                    buttonName: '业务咨询'
                },
                schemeIntroduction: {
                    name: '方案介绍',
                    describe: '在电子证据的存证和取证过程中需要保证数据的完整性，确保数据不被任何因素破坏。区块链天然具备的数据多级加密和不可篡改特性大大减少了取证的负担，一旦上链的存证就不能被篡改，保证了数据的完整性和安全性。此外，数据连续性在存证和取证中是需要坚持的一个重要原则。比如在法院的取证中，证据提交必须能说明在证据初始获取状态到出现的状态之间的变化。区块链的可溯源特性使得通过时间戳顺序产生的区块具有连续不可篡改的特性，可追溯存证数据从初始状态到提交状态的全流程状态，完全吻合这一需求。'
                },
                operationFlow: {
                    name: '业务流程',
                    img: operationFlowPNG
                },
                businessValue: {
                    name: '业务价值',
                    titleHeight: '1.76rem',
                    showBoxComData: [
                        { name: '多维存证', image: boxCom1PNG, content: '支持文件、表单、截图等多种类型的存证方式，满足用户在不同场景下的存证需求。' },
                        { name: '可信存储体系', image: boxCom2PNG, content: '区块链技术保证了电子证据的可追溯性和不可篡改性，权威节点参与共识提高数据存证的可信度。' },
                        { name: '业务灵活对接', image: boxCom3PNG, content: '提供丰富的API接口，基于基础的区块链存证能力支持各类存证场景应用，可拓展性极强。' }
                    ]
                },
                applicationCases: {
                    name: '应用案例',
                    subName: '版权链',
                    partnerTitle: '合作方',
                    partnerText: '北京文创动力、首都版权协会、北京市版权局',
                    caseIntroductionTitle: '案例介绍',
                    caseIntroductionText: '安链科技与首都版权协会合作，为北京版权局提供数字版权证书服务，建立版权链，打通北京互联网法院，上链数据超760万。北京数字版权登记证书系统和版权证书链系统，代表着国内版权确权数字化的最新成果和最高水平。通过版权证书数字化和上链，解决了发展数字版权产业迫切需要解决的版权资产数字化和数字版权资产上链存储问题。'
                }
            }
        };
    }

    headerOnClick =() => {
        this.props.history.push({ pathname: '/programAns/blockchainCertificate' });
    };

    render() {
        let { data } = this.state;
        return (
            <div className="blockchainCertificate">
                <CommonSolution headerOnClick={this.headerOnClick} data={data} />
            </div>
        );
    }
}
