/**
 * 解决方案-区块链数字版权
 */
import React, { Component } from "react";
import './blockchainCopyright.scss';
import CommonSolution from '../../components/commonSolution';
import operationFlowPNG from '../../assets/images/solution/digitalCopyrightOperationFlow.png';
import boxCom1PNG from '../../assets/images/andChain/first.png';
import boxCom2PNG from '../../assets/images/andChain/second.png';
import boxCom3PNG from '../../assets/images/andChain/third.png';
import boxCom4PNG from '../../assets/images/andChain/forth.png';
import CommonFixedButton from "../../components/commonFixedButton/CommonFixedButton";

export default class BlockchainCopyright extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                header: {
                    name: '区块链数字版权',
                    describe: '基于区块链技术的数字版权登记、交易、监测和分发',
                    buttonName: '业务咨询'
                },
                schemeIntroduction: {
                    name: '方案介绍',
                    describe: '区块链技术能够制造一个不可更改的时间戳，将作者、创作时间和内容生成一个独一无二的“哈希”摘要，再将哈希摘要记录到区块链当中。区块链不可篡改的特性保证了其上传时间的真实性，使其拥有了法律效力。通过区块链存证在版权业务中的应用，将确权信息、版权流转、侵权证据上链，帮助版权管理方完善司法保护和版权权益的分发和激励。基于区块链数字版权平台，数字作品⽣产者能够将数字版权登录上链、挂牌和分发授权；产权管理的平台能够对版权进⾏审批、管理和监控；普通⽤户可以自由购买卖出版权的权益。'
                },
                operationFlow: {
                    name: '业务流程',
                    img: operationFlowPNG
                },
                businessValue: {
                    name: '业务价值',
                    titleHeight: '2.1rem',
                    showBoxComData: [
                        { name: '版权登记', image: boxCom1PNG, content: '创作者能够简单、快捷、低成本地完成原创声明、版权申请和登记。身份信息、版权信息、作品信息全部上链。' },
                        { name: '版权交易', image: boxCom2PNG, content: '在区块链上对作品的版权进⾏透明、程式化的买卖，利⽤区块链唯⼀真实的特点实现对版权的拆分交易。' },
                        { name: '版权监测', image: boxCom3PNG, content: '对互联网上的数字作品的传播进行监测，⼀旦发现侵权行为，将相关证据上链完成固化保全，并通知相关权利主体。' },
                        { name: '版权分发', image: boxCom4PNG, content: '授权相关渠道分发数字作品，区块链对作品的分发情况进行记录和保存，并按照分销的收益收取与之相应的版权费⽤。' }
                    ]
                },
                applicationCases: {
                    name: '应用案例',
                    subName: '版权链',
                    partnerTitle: '合作方',
                    partnerText: '北京文创动力、首都版权协会、北京市版权局',
                    caseIntroductionTitle: '案例介绍',
                    caseIntroductionText: '安链科技与首都版权协会合作，为北京版权局提供数字版权证书服务，建立版权链，打通北京互联网法院，上链数据超760万。北京数字版权登记证书系统和版权证书链系统，代表着国内版权确权数字化的最新成果和最高水平。通过版权证书数字化和上链，解决了发展数字版权产业迫切需要解决的版权资产数字化和数字版权资产上链存储问题。'
                }
            }
        };
    }

    headerOnClick =() => {
        this.props.history.push({ pathname: '/programAns/blockchainCopyright' });
    };

    render() {
        let { data } = this.state;
        return (
            <div className="blockchainCopyright">
                <CommonSolution headerOnClick={this.headerOnClick} data={data} />
            </div>
        );
    }
}
