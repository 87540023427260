/**
 * Created by zal on 2020/11/2
 * 解决方案-应用案例
 * @param  data
 * { name: String, subName: String, partnerTitle: String, partnerText: String,
 * caseIntroductionTitle: String, caseIntroductionText: String }
 */
import React from 'react';
import './commonDivRoot.scss';
import blockPNG from '../../../assets/images/solution/block.png';

function CommonApplicationCases ({ data }) {
    return (
        <div className={'commonDivRoot'} style={{ backgroundColor: '#F8F9FC' }}>
            <div className={'commonDiv'}>
                <div className={'commonTitle'}>{data?.name || '应用案例'}</div>
                <div className={'applicationCasesRoot'}>
                    <div className={'applicationCasesRootTitle'}>
                        <span>{data?.subName || ''}</span>
                        <img src={blockPNG} alt=""/>
                    </div>
                    {
                        data?.partnerText ?
                            <div className={'partnerRoot'}>
                                <div className={'commonIconTitle'}>{data?.partnerTitle || '合作方'}</div>
                                <div className={'commonDivRootText'}>{data?.partnerText || ''}</div>
                            </div> : null
                    }
                    <div className={'lastTextRoot'}>
                        <div className={'commonIconTitle'}>{data?.caseIntroductionTitle || '案例介绍'}</div>
                        <div className={'commonDivRootText'}>{data?.caseIntroductionText || ''}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommonApplicationCases;
