import React, { Component } from 'react';
import {isMobile} from '../../utils/util';
import "./commonLeftDrawer.scss";
import "./mobileCommonLeftDrawer.scss";
import * as antdMobile from 'antd-mobile';
import fastData from "../../assets/images/andChain/fastData.png";
import rightImg from "../../assets/images/andLogistics/rightImg.png";

const AntdMobileCarousel = antdMobile.Carousel;

class CommonLeftDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            selectIndex: 0
        };
    }

    render() {
        let { DataFastService, rightImg } = this.props;
        let { isMobile, selectIndex } = this.state;
        return (
            <div
                className={`commonLeftDrawer ${isMobile ? 'mobileCommonLeftDrawer' : ''}`}
                style={{minWidth: isMobile ? '' : '1160px'}}>
                    <div className="leftContent">
                        {
                            DataFastService.map((item, index) => {
                                return <div className={selectIndex === index && !isMobile ? "box boxActive" : "box"}
                                onMouseMove={() => {
                                    this.setState({
                                        selectIndex: index
                                    })
                                }}>
                                    <div className={'productItemActiveBg1'}/>
                                    <div className={'productItemActiveBg2'}/>
                                    <div className="leftName"> <p className="point"></p>{item.name}</div>
                                    <div className="rightTitle">{item.content}</div>
                                </div>
                            })
                        }
                    </div>
                    <img src={rightImg} className="rightImg"></img>
            </div>
        );
    }
}

export default CommonLeftDrawer;