import React, { Component } from 'react';
import arrow from '../../assets/images/anstar/arrow.png';
import "./commonAnstarSafety.scss";
import "./mobileCommonAnstarSafety.scss";
import {isMobile} from '../../utils/util';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

class CommonAnstarSafety extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    render() {
        let { chooseItem } = this.props;
        let { isMobile } = this.state;
        let len = chooseItem.subTitleArr.length; 
        return (
            <div
                className={`commonAnstarSafety ${isMobile ? 'mobileCommonAnstarSafety' : ''}`}
                style={{minWidth: isMobile ? '' : '1160px'}}>
                {
                    isMobile ?
                    <div className="anstarTopTitleBtn">
                        <LeftCircleOutlined
                            onClick={() => this.props.changeItem('pre')}
                            className="circleOutlined"
                        />
                            {chooseItem.name}
                        <RightCircleOutlined
                            onClick={() => this.props.changeItem('next')}
                            className="circleOutlined"
                        />
                    </div> : ''
                }

                <div className="leftTitleInfo"
                    style={{ width: len === 4 ? 'calc(100% - 420px)' : 'calc(100% - 660px)' }}>
                    <div className="commonHeaderTitle">
                        {chooseItem.headerTitle[0]}<br />
                        {chooseItem.headerTitle[1]}
                    </div>
                    <img src={arrow} alt="" />

                    <div className="leftBottomInfo">
                        {
                            chooseItem.subTitleArr.map((item, index) => {
                                return <div className="leftDetailInfo"
                                    style={{ width: len === 4 ? '50%' : '90%' }}>
                                    <span className="leftName">{item.name}</span>
                                    <div className="leftTitle"
                                    >{item.title}</div>
                                </div>
                            })
                        }
                    </div>

                </div>
                <img src={chooseItem.img} className="rightImg"
                    style={{ width: len === 4 ? '420px' : '660px' }} />
            </div>
        );
    }
}

export default CommonAnstarSafety;