// import fetch from 'cross-fetch';

/**
 * 是否手机端访问
 * @returns {boolean}
 */
export function isMobile() {
    let ua = navigator.userAgent;
    let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    let isIphone =!ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    let isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    let isMobile = isIphone || isAndroid;
    //判断
    return !!isMobile;
}

/**
 * 方法下载
 * @param url
 * @returns {Promise<any>}
 */
export function fetchDownload(url, opt) {
    let promise = new Promise((resolve) => {
        fetch(url, opt).then(res => res.blob().then(blob => {
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob); // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
            let filename = window.decodeURI(res.headers.get('Content-Disposition').split('=')[1]); // 获取文件名并处理文件名编码问题
            filename = filename.replace('utf-8\'zh_cn\'', '');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }).then(() => {
            resolve();
        }));
    });
    return promise;
}
// 判断简繁体

export function translate(type, text) {
    let json = {
        '首页': ['首页', 'homePage']
    };
    if (type === 1) { // 简体
        return json[text][0];
    }
    return json[text][1];
}

// 内部函数, 用于判断对象类型
function _getClass(object) {
    return Object.prototype.toString.call(object).match(/^\[object\s(.*)\]$/)[1];
}

export function isArray(obj) {
    return _getClass(obj).toLowerCase() === 'array';
}

export function isString(obj) {
    return _getClass(obj).toLowerCase() === 'string';
}

export function isDate(obj) {
    return _getClass(obj).toLowerCase() === 'date';
}

export function isObject(obj) {
    return _getClass(obj).toLowerCase() === 'object';
}

export function isNumber(obj) {
    return _getClass(obj).toLowerCase() === 'number' && !isNaN(obj);
}

export function setCookie(name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    // document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString();
    document.cookie = name + '=' + encodeURI(value);
}

export function getCookie(name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(name + '=');
        if (c_start !== -1) {
            c_start = c_start + name.length + 1;
            var c_end = document.cookie.indexOf(';', c_start);
            if (c_end === -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
}

export function clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
        }
    }
}

/**
 * @desc 判断参数是否为空, 包括null, undefined, [], '', {}
 * @param {object} obj 需判断的对象
 */
export function isEmpty(obj) {
    var empty = false;

    if (obj === null || obj === undefined) {    // null and undefined
        empty = true;
    } else if ((isArray(obj) || isString(obj)) && obj.length === 0) {
        empty = true;
    } else if (isObject(obj)) {
        var hasProp = false;
        for (let prop in obj) {
            if (prop) {
                hasProp = true;
                break;
            }
        }
        if (!hasProp) {
            empty = true;
        }
    }
    return empty;
}

/**
 * @desc 判断参数是否不为空
 */
export function isNotEmpty(obj) {
    return !isEmpty(obj);
}

/**
 * @desc 判断参数是否为空字符串, 比isEmpty()多判断字符串中有空格的情况, 如: '   '.
 * @param {string} str 需判断的字符串
 */
export function isBlank(str) {
    if (isEmpty(str)) {
        return true;
    } else if (isString(str) && str.trim().length === 0) {
        return true;
    }
    return false;
}

/**
 * @desc 判断参数是否不为空字符串
 */
export function isNotBlank(obj) {
    return !isBlank(obj);
}

/**
 * @desc 生成一个随机id
 */
export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * @desc 根据对象和传入的对象value属性的值, 查询value对应的name值
 * @param {object} obj 需遍历的对象
 * @param {string} value 需搜索的value属性的值
 * @demo USER = {
 *           A: {
 *               name: '普通会员',
 *               value: 0
 *           },
 *           B: {
 *               name: 'VIP会员',
 *               value: 1
 *           }
 *       }
 */
export function searchNameByVal(obj, value) {
    if (isEmpty(obj) || isEmpty(value)) {
        return '';
    }

    for (let prop in obj) {
        if (obj[prop].value === value) {
            return obj[prop].name;
        }
    }
}

export function signKeyWords(str, words = [], color = '#00AFE3') {
    if (words.length < 1) {
        return str;
    }

    var map = {}, reg, items;
    var regStr = `(${words.join('|')})`;

    words.forEach((e) => {
        e !== '' && (map[e] = true);
    });
    reg = new RegExp(regStr, 'g');

    items = str.replace(reg, '#$1#').split(/#+/);

    var result = [];

    for (var i = 0; i < items.length; i++) {
        if (items[i] === '') {
            continue;
        }
        if (map[items[i]]) {
            result.push(`<strong style="color: ${color};">${items[i]}</strong>`);
        } else {
            result.push(`<span>${items[i]}</span>`);
        }
    }

    return result.join('');
}


export function dateFormat(formatDate) {
    return formatDate.replace(/(.{4})(.{2})/, '$1-$2-');
}


/**
 * 检查元素是否在数组中
 * @param arr
 * @param obj
 * @returns {boolean}
 */
export function contains(arr, obj) {
    let i = arr.length;
    while (i--) {
        if (arr[i] === obj) {
            return true;
        }
    }
    return false;
}

/**
 * 生成随机整数
 * @param min
 * @param max
 * @constructor
 */
export function random(min, max) {
    min = min || -90;
    max = max || 90;
    return min + Math.floor(Math.random() * (max - min));
}

/**
 * 指定位置插入字符串
 * @param str
 * @param flg
 * @param sn
 * @returns {string}
 */
export function insert_flg(str, flg, sn) {
    let newStr = '';
    for (let i = 0; i < str.length; i += sn) {
        let tmp = str.substring(i, i + sn);
        newStr += tmp + flg;
    }
    return newStr;
}

/**
 * 判断是不是对象
 * @param obj
 * @returns {boolean}
 */
export function isFormData(obj) {
    return obj instanceof FormData;
}


/**
 *
 */
export function getGraphic(width, height) {
    let graphic = [
        {
            type: 'image',
            id: 'logo',
            left: 'center',
            top: 'center',
            z: 2,
            bounding: 'all'
            // style: {
            //     image: pieChartRound,
            //     width: width,
            //     height: height
            // }
        }
    ];
    return graphic;
}

/**
 * 饼图或漏斗图提示框 format 需要自己定义
 * @returns {{padding: number[], backgroundColor: string, borderColor: string, borderWidth: number, extraCssText: string, show: boolean, trigger: string, textStyle: {color: string, fontSize: string}}}
 */
export function getTooTipConfig() {
    let tooltip = {
        show: true,
        backgroundColor: 'RGBA(19,44,93,0.9)',
        padding: [20, 40, 20, 40],
        trigger: 'item',
        borderWidth: 1,
        borderColor: '#368DFF',
        extraCssText: 'box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.55);',
        textStyle: {
            color: '#333333',
            fontSize: '14'
        }
    };
    return tooltip;
}

/**
 * 柱状图提示框
 * @returns {{formatter: (function(*): string), padding: number[], backgroundColor: string, borderWidth: number,
 * extraCssText: string, trigger: string, textStyle: {color: string, fontSize: string}}}
 */
export function getTooTipBarConfig() {
    let tooltip = {
        backgroundColor: 'RGBA(12, 46, 101, 0.7)',
        padding: [10, 10, 10, 10],
        trigger: 'item',
        borderWidth: 0,
        extraCssText: 'box-shadow: -3px 3px 14px 4px #053a6b',
        textStyle: {
            color: '#fff',
            fontSize: '14'
        },
        formatter: (param) => {
            let { data } = param;
            let { value, name } = data;
            return `<div>
                            <span class="seriesName">${name}</span><br/>
                            <span class="seriesName">数量:</span><span class="value">${value}</span>
                     <div>`;
        }
    };
    return tooltip;
}

/**
 *
 * @param titleNameIsNotNum 标题样式为数字还是汉字
 * @returns {{backgroundColor: string, padding: number[], trigger: string, borderWidth: number, extraCssText: string, t
 * extStyle: {color: string, fontSize: string}, formatter: function(*): string}}
 */
export function getTooTipConfigByLineOrBar(titleNameIsNotNum = false) {
    let tooltip = {
        backgroundColor: 'RGBA(12, 46, 101, 0.7)',
        padding: [10, 10, 10, 10],
        trigger: 'item',
        borderWidth: 0,
        extraCssText: 'box-shadow: -3px 3px 14px 4px #053a6b',
        textStyle: {
            color: '#fff',
            fontSize: '14'
        },
        formatter: (param) => {
            let { data } = param;
            let { value, name } = data;
            return `<div>
                        <span class=${titleNameIsNotNum ? 'seriesTitle' : 'seriesTitleToNum'}>${name}</span><br/>
                        <span class="seriesName">数量:</span><span class="value">${value}</span>
                     <div>`;
        }
    };
    return tooltip;
}

/**
 * 构造柱状图/折线图上每个数据的显示
 * @returns {{fontFamily: string, color: string, show: boolean, fontSize: string}}
 */
export function getLineLabelConfig() {
    let labelConfig = {
        show: true,
        fontSize: '20',
        color: 'white',
        fontFamily: 'fontF'
    };
    return labelConfig;
}

/**
 * 饼图配置文件
 * @param data
 * @param color
 * @param nameArray
 * @param unit
 */
export function getPieOption(data, color) {
    let option = {
        series: [
            {
                name: '风险等级',
                type: 'pie',
                radius: '60%',
                center: ['50%', '50%'],
                data: data,
                minAngle: 5,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                color: color,
                labelLine: {
                    normal: {
                        show: true,
                        length: 15,
                        length2: 30
                    }
                },
                label: {
                    normal: {
                        formatter: '{b|{b}} ',
                        backgroundColor: 'RGBA(12, 46, 101, 0)',
                        borderWidth: 1,
                        borderRadius: 4,
                        padding: [2, 2],
                        rich: {
                            b: {
                                color: '#fff',
                                fontSize: 17,
                                lineHeight: 25,
                                align: 'left'
                            }
                        }
                    }
                }
            }
        ]
    };
    return option;
}

/**
 * 圆环
 * @param data
 * @param color
 * @returns {{series: {data: *, color: *, center: string[], name: string, itemStyle: {emphasis: {shadowOffsetX: number, shadowBlur: number, shadowColor: string}}, minAngle: number, labelLine: {normal: {length2: number, show: boolean, length: number}}, label: {normal: {formatter: string, padding: number[], backgroundColor: string, borderRadius: number, borderWidth: number, rich: {b: {color: string, fontSize: number, lineHeight: number, align: string}}}}, type: string, radius: string[]}[]}}
 */
export function getPieRoundOption(data, color) {
    let option = {
        series: [
            {
                name: '风险等级',
                type: 'pie',
                radius: ['50%', '65%'],
                center: ['50%', '50%'],
                data: data,
                minAngle: 5,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                color: color,
                labelLine: {
                    normal: {
                        show: true,
                        length: 15,
                        length2: 30
                    }
                },
                label: {
                    normal: {
                        formatter: '{b|{b}} ',
                        backgroundColor: 'RGBA(12, 46, 101, 0)',
                        borderWidth: 1,
                        borderRadius: 4,
                        padding: [2, 2],
                        rich: {
                            b: {
                                color: '#fff',
                                fontSize: 17,
                                lineHeight: 25,
                                align: 'left'
                            }
                        }
                    }
                }
            }
        ]
    };
    return option;
}

/**
 * 折线图中xy轴的坐标配置文件构建
 * @param xName
 * @param xDataArray
 * @param yName
 * @returns {{yAxis: {axisLabel: {color: string}, axisLine: {lineStyle: {color: string, type: string}},
 * name: *, axisTick: {show: boolean}, splitLine: {lineStyle: {color: string}}, type: string},
 * xAxis: {axisLabel: {color: string}, data: *, axisLine: {lineStyle: {color: string, type: string}},
 * name: *, axisTick: {show: boolean}, type: string}}}
 */
export function getLineXyOption(xName, xDataArray, yName) {
    let option = {
        xAxis: {
            name: xName,
            nameTextStyle: {
                color: '#EEEEEE'
            },
            type: 'category',
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    type: 'solid',
                    color: '#305B9E' // 左边线的颜色
                }
            },
            axisLabel: {
                color: '#8FB1DE'
            },
            data: xDataArray
        },
        yAxis: {
            name: yName,
            nameTextStyle: {
                color: '#EEEEEE'
            },
            type: 'value',
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    type: 'solid',
                    color: '#305B9E' // 左边线的颜色
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#305B9E'
                }
            },
            axisLabel: {
                color: '#8FB1DE'
            }
        }
    };
    return option;
}

/**
 * 环形标题的配置文件
 * @type {{normal: {show: boolean, position: string}, emphasis: {formatter: (function(*): string), show: boolean,
 * rich: {a: {color: string, fontSize: number, fontWeight: number}, b: {color: string, fontSize: number}},
 * textStyle: {fontSize: string, fontWeight: string}}}}
 */
export const ringPieLabelOption = {
    normal: {
        show: false,
        position: 'center'
    },
    emphasis: {
        show: true,
        textStyle: {
            fontSize: '27',
            fontWeight: 'bold'
        },
        formatter: (params) => {
            let result = '';
            let name = params.name;
            let name1 = '';
            if (name.slice(7, 14) && name.slice(7, 14) !== '') {
                name1 += '\n' + name.slice(7, 14);
            }
            if (name.slice(14, 21) && name.slice(14, 21) !== '') {
                name1 += '\n' + name.slice(14, 21);
            }
            if (name.slice(21) && name.slice(21) !== '') {
                name1 += '\n' + name.slice(21);
            }
            result +=
                '{a|' + params.percent.toFixed(2) + '% }' + '\n' +
                '{b|' + name.slice(0, 7) + name1 + '}'
            ;
            return result;
        },
        rich: {
            a: {
                color: '#ffce31',
                fontSize: 22,
                fontWeight: 600
            },
            b: {
                color: '#fff',
                fontSize: 14
            }
        }
    }
};

/**
 * 环形标题的配置文件
 * @type {{normal: {show: boolean, position: string}, emphasis: {formatter: (function(*): string), show: boolean,
 * rich: {a: {color: string, fontSize: number, fontWeight: number}, b: {color: string, fontSize: number}},
 * textStyle: {fontSize: string, fontWeight: string}}}}
 */
export const ringPieLabelOptionTrue = {
    normal: {
        show: false,
        position: 'center'
    },
    emphasis: {
        show: true,
        textStyle: {
            fontSize: '27',
            fontWeight: 'bold'
        },
        formatter: (params) => {
            let { data } = params;
            let { name, ratio } = data;
            let result = '';
            let nameOrigin = name;
            let name1 = '';
            if (name.slice(7, 14) && nameOrigin.slice(7, 14) !== '') {
                name1 += '\n' + nameOrigin.slice(7, 14);
            }
            if (nameOrigin.slice(14, 21) && nameOrigin.slice(14, 21) !== '') {
                name1 += '\n' + nameOrigin.slice(14, 21);
            }
            if (nameOrigin.slice(21) && nameOrigin.slice(21) !== '') {
                name1 += '\n' + nameOrigin.slice(21);
            }
            result +=
                '{a|' + ratio + '}' + '\n' +
                '{b|' + nameOrigin.slice(0, 7) + name1 + '}'
            ;
            return result;
        },
        rich: {
            a: {
                color: '#ffce31',
                fontSize: 22,
                fontWeight: 600
            },
            b: {
                color: '#fff',
                fontSize: 14
            }
        }
    }
};

/**
 * 获取柱状图的渐变色
 * @constructor
 */
export function getLinearGradientColor(barColor) {
    let color =
        [
            {
                offset: 0, color: 'rgba(' + barColor + ', 0)'
            },
            {
                offset: 0.5, color: 'rgba(' + barColor + ', 0.5)'
            },
            {
                offset: 1, color: 'rgba(' + barColor + ', 1)'
            }
        ];
    return color;
}

/**
 * 获取表格索引序号
 * @param index
 * @param start
 * @param size
 * @returns {*}
 */
export function getTableIndex(index, start, size = 10) {
    return index + 1 + (start - 1) * size;
}

/**
 * 当数据过大的时候进行，分割
 * @param data
 * @returns {string}
 */
export function splitData(data) {
    data = `${data}`;
    let splitData = '';
    let length = data.length;
    if (length <= 3) {
        return data;
    } else if (3 < length && length <= 6) {
        splitData = data.substring(0, length - 3) + ',' + data.substring(length - 3, length);
        return splitData;
    } else if (6 < length && length <= 9) {
        splitData = data.substring(0, length - 6) + ',' + data.substring(length - 6, length - 3) + ',' + data.substring(length - 3, length);
        return splitData;
    } else if (9 < length && length <= 12) {
        splitData = data.substring(0, length - 9) + ',' + data.substring(length - 9, length - 6) + ',' + data.substring(length - 6, length - 3) + ',' + data.substring(length - 3, length);
        return splitData;
    }
    return data;
}

/**
 * 构造空值
 * @param value
 * @returns {*}
 */
export function constructorNullValue(value) {
    if (value === '' || value === null || value === undefined) {
        return '';
    }
    return value;
}

/**
 * 时间戳转日期字符串,输入YYYY-MM-DD
 * @param dateNum
 * @returns {string}
 */
export function dateNumToString(dateNum) {
    if (dateNum && dateNum !== null && dateNum !== '') {
        let date = new Date(dateNum);
        let separator = '-';
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = '0' + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate;
        }
        let currentDate = year + separator + month + separator + strDate;
        return currentDate;
    }
    return '—';
}

/**
 * 文件下载
 * @param {string} url 请求地址
 * @param {object} params  请求参数 如{name: '123'}
 * @returns {Promise}
 */
export function fileDownload(url, params, type = 'GET') {
    const config = require('../resources/config.dev');
    let qs = require('qs');
    let urlStr = `${config.default.server.api}${url}`;
    if (type === 'GET') {
        urlStr += '?';
        urlStr += qs.stringify(params);
    }
    let opt = {
        method: type
    };
    if (type.toLocaleLowerCase() === 'post') {
        opt.body = JSON.stringify(params);
        opt.headers = {
            'Content-Type': 'application/json'
        };
    }
    return fetchDownload(urlStr, opt);
}

export function overtopOmit(str, num, ) {
    if (str && str.length > num) {
        return str.substring(0, num) + '...';
    }
    return str;
}
