/**
 * 业务咨询
 */
import React, { Component } from 'react';
import "./programAns.scss";
import "./mobileCommonProgramAns.scss";
import { Form, Input, Select, Button, Modal, message } from 'antd';
import accomplish from '../../assets/images/programAns/accomplish.png';
import { programAnsAdd } from '../../services/programAns'
import { isMobile } from '../../utils/util';

class ProgramAns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            schemeArr: [
                { name: 'AndChain区块链底层平台', code: 'andChain' },
                { name: 'AndLogistics物流管理平台', code: 'andLogistics' },
                { name: 'AndFinance供应链金融平台', code: 'andFinance' },
                { name: 'AndTracking溯源平台', code: 'andTracking' },
                { name: 'Anstar企业行为安全感知平台', code: 'anstar' },
                { name: 'Compass智能图分析平台', code: 'compass' },
                { name: '区块链存证', code: 'blockchainCertificate' },
                { name: '区块链数字版权', code: 'blockchainCopyright' },
                { name: '区块链电子证照', code: 'blockchainLicense' },
                { name: '区块链网络赋强公证', code: 'blockchainNetwork' },
                { name: '其他', code: 'other' },
            ],
            scheme: this.props.type === 'anstar' ? 'anstar' : this.props.match && this.props.match.params && this.props.match.params.type ?
                this.props.match.params.type === 'home' ? '您希望咨询哪款产品？' : this.props.match.params.type : '',
            visible: false,
        }
    };

    validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    findElem(arrayToSearch, attr, val) {
        for (let i = 0; i < arrayToSearch.length; i++) {
            if (arrayToSearch[i][attr] == val) {
                return i;
            }
        }
        return -1;
    }

    onFinish = values => {
        const { schemeArr, scheme } = this.state;
        let schemeChoose = schemeArr[this.findElem(schemeArr, 'code', scheme === 'anstar' ? 'anstar' : values.scheme)];
        if (schemeChoose) {
            const params = {
                product: schemeChoose.name || '',
                custom: values.name,
                company: values.companyName,
                email: values.email,
                phone: values.phone,
                context: values.introduction
            };
            programAnsAdd(params).then((res) => {
                if (res.result === 0) {
                    this.setState({
                        visible: true
                    });
                }
            });
        } else {
            message.info('请选择需要咨询的产品');
        }
    };

    handleChange = e => {
        this.setState({
            visible: false
        });
    };

    toBack = () => {
        let { scheme } = this.state;
        if (scheme) {
            this.setState({
                visible: false
            })
        } else {
            window.history.back();
        }
    };

    render() {
        const { schemeArr, visible, scheme, isMobile } = this.state;
        let { type } = this.props;
        return (
            <div className={`commonProgramAns ${isMobile ? 'mobileCommonProgramAns' : ''}`} id='commonProgramAns'
            // style={{ marginTop: type === 'anstar' ? '2rem' : '0' }}
            >
                <div className={'programAnsHeader'}
                    style={{ background: type === 'anstar' ? 'transparent' : '' }}
                >
                    <div className={'subHeaderRoot'}>
                        <div className={'programAnsName'}
                            style={{
                                color: type === 'anstar' ? '#000000' : '#ffffff',
                                fontWeight: type === 'anstar' ? '500' : '600',
                                fontSize: type === 'anstar' ? '0.34rem' : '0.32rem'
                            }}
                        >{type === 'anstar' ? '免费试用' : '业务咨询'}</div>
                        {
                            type === 'anstar' ? ''
                                :
                                <div className={'programAnsDescribe'}>我们秉承专业的服务理念，期待与您携手合作</div>
                        }
                    </div>
                </div>
                <div className={'programAnsForm'}>
                    <Form name="nest-messages" initialValues={{
                        ['scheme']: scheme
                    }} layout="vertical" onFinish={this.onFinish} validateMessages={this.validateMessages}>
                        {
                            type === 'anstar' ? ''
                                :
                                <Form.Item name="scheme" label="产品与解决方案" rules={[{ required: true, message: '请填写产品与解决方案' }]}>
                                    <Select placeholder={'您希望咨询哪款产品？'}
                                        style={{ color: scheme === '您希望咨询哪款产品？' ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.9)' }}
                                        onChange={(value) => {
                                            this.setState({
                                                scheme: value
                                            })
                                        }}
                                    >
                                        {schemeArr ? schemeArr.map((item, key) => <Select.Option key={key} value={item.code}>{item.name}</Select.Option>)
                                            : null
                                        }
                                    </Select>
                                </Form.Item>
                        }
                        <Form.Item name="name" label="姓名" rules={[{ required: true, message: '请填写姓名' }]}>
                            <Input placeholder={'该如何称呼您'} />
                        </Form.Item>
                        <Form.Item name="phone" label="手机号" rules={[{ required: true, message: '请填写手机号' }]}>
                            <Input placeholder={'您的手机号是？'} maxLength={11} />
                        </Form.Item>
                        {
                            type === 'anstar' ? ''
                                :
                                <Form.Item name="email" label="邮箱" rules={[{ type: 'email', required: true, message: '请填写邮箱' }]}>
                                    <Input placeholder={'您的邮箱是？'} />
                                </Form.Item>
                        }

                        <Form.Item name="companyName" label="公司名称" rules={[{ required: true, message: '请填写公司名称' }]}>
                            <Input placeholder={'您的公司名称是？'} />
                        </Form.Item>
                        {/* <Form.Item name="introduction" label="合作/咨询内容" rules={[{ required: true, message: '请填写合作/咨询内容' }]}>
                            <Input.TextArea placeholder={'请简单介绍下合作内容（不超过500字）'} rows={4} maxLength={500} />
                        </Form.Item> */}
                        <Form.Item>
                            <div className={'btn'}>
                                <Button type="primary" htmlType="submit">
                                    提交申请
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <Modal
                    visible={visible}
                    onOk={this.handleChange}
                    onCancel={this.handleChange}
                    footer={null}
                    width={'4rem'}
                    className={'affirmModal'}
                    getContainer={() => document.getElementById('commonProgramAns')}
                >
                    <div className={'affirmContent'}>
                        <img src={accomplish} alt="" className={'img'} />
                        <div className={'succeed'}>提交成功</div>
                        <div className={'hint'}>工作人员将在3个工作日内与您联系</div>
                        <Button className={'consultButton'} style={{ height: '.41rem' }} onClick={this.toBack}>知道了</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ProgramAns;
