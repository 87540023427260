// AndChain区块链底层平台
import React, {Component} from "react";
import "./andChain.scss";
import "./mobileAndChain.scss";
import {
    Layout,
    Button,
    message,
} from "antd";
import {requestTry} from "../../services/compass";
import ShowBoxCom from "../../components/showBoxCom/ShowBoxCom";
import HighBoxCom from "../../components/highBoxCom/HighBoxCom";
import CommonBottomBox from "../../components/commonBottomBox/CommonBottomBox";
import CommonLeftDrawer from "../../components/CommonLeftDrawer/CommonLeftDrawer";

import fastData from "../../assets/images/andChain/fastData.png";
import {isMobile} from '../../utils/util';

const {Content} = Layout;

const DataTechnologArr = [
    {
        name: '高性能支持',
        image: require('../../assets/images/andChain/first.png'),
        content: '性能高达2W+TPS的企业级联盟链底层平台，为政府、企业、产业联盟提供强大的区块链技术支撑。'
    },
    {
        name: '高存储安全',
        image: require('../../assets/images/andChain/second.png'),
        content: '支持国密，具有高金融可用性，具备完善的身份验证与权限管理机制，全方面保障用户数据安全和隐私安全的同时，能够实现权限可控的数据共享。'
    },
    {
        name: '高可扩展性',
        image: require('../../assets/images/andChain/third.png'),
        content: '成熟的模块化微服务架构，支持服务器节点扩充以及区块链系统模块的横向扩展，从而满足日益增长的数据扩容需求和功能拓展需求。'
    },
    {
        name: '低实施成本',
        image: require('../../assets/images/andChain/forth.png'),
        content: '最低3台服务器即可运行，系统模块按需配置，针对政府机构、企业、产业联盟和独立开发者提供从行业咨询到应用开发落地的全流程服务，降低客户的整体实施成本。'
    }
];

const DataFastService = [
    {
        name: '一键部署',
        content: '脚本一键自动化部署，极大地压缩区块链系统的构建时间，将数据快速上链。'
    },
    {
        name: '可视化运维',
        content: '屏蔽区块链底层的复杂度，降低开发者门槛，大幅提高区块链应用的开发效率和运行监控管理能力。'
    },
    {
        name: '区块链浏览器',
        content: '区块链的每一个区块和交易，都可以在区块链浏览器找到相应的记录，保证了链上操作的可追溯性。'
    },
    {
        name: '全场景覆盖',
        content: '基于区块链基础平台能力，可以快速覆盖版权保护、商品溯源、电子合同、司法公证等业务场景。'
    }
];

const DataPrivate = [
    {
        name: '使用门槛低',
        content: '最低3台服务器即可运行，助力企业低成本上链。'
    },
    {
        name: '极速部署',
        content: '小时级自动化部署，最短时间搭建链上服务。'
    },
    {
        name: '全方位支撑',
        content: '软硬件一体化实施方案，量体裁衣，专注细节。'
    },
    {
        name: '全程技术支持',
        content: '成熟的售前及运维服务体系，为企业免除后顾之忧。'
    }
];

const DataScenariosgArr = [
    {
        name: '存证',
        bg: require('../../assets/images/andChain/onebg.png'),
        image: require('../../assets/images/andChain/bottomOne.png'),
        content: '区块链技术能够制造一个不可更改的时间戳，对存证内容生成一个独一无二的“哈希”摘要，再将哈希摘要记录到区块链当中。区块链不可篡改的特性保证了其上传时间的真实性，使其拥有了法律效力。'
    },
    {
        name: '溯源',
        bg: require('../../assets/images/andChain/twobg.png'),
        image: require('../../assets/images/andChain/bottomTwo.png'),
        content: '对商品生产、加工、批发、零售的全供应链流程进行记录和存证，实现对商品供应链上每个流转环节的追溯查询，不仅可以准确获取商品的来源信息，还可以对商品的销售分布展开分析。'
    },
    {
        name: '金融',
        bg: require('../../assets/images/andChain/threebg.png'),
        image: require('../../assets/images/andChain/bottomThree.png'),
        content: '基于底层联盟链技术能力以及分布式身份、权限管理、高并发转帐等功能模块，在金融业态各主体之间建立可信的信息共享生态网络，创建新一代高效可靠的金融信用体系。'
    },
    {
        name: '政务',
        bg: require('../../assets/images/andChain/fourbg.png'),
        image: require('../../assets/images/andChain/bottomFour.png'),
        content: '由政府职能主体共同组成联盟链点对点网络，打破信息孤岛，实现政务数据的确权、共享和追溯。在此基础上，支持职能主体在监督和授权的前提下，安全可信的使用共享政务数据，大幅提高业务效率和服务能力。'
    }
];

const partnerImages = [
    require('../../assets/images/partnerBigImgs/JLM.png'),
    require('../../assets/images/partnerBigImgs/RMT.png'),
    require('../../assets/images/partnerBigImgs/TPYBX.png'),
    require('../../assets/images/partnerBigImgs/BBD.png'),
    require('../../assets/images/partnerBigImgs/BCCN.png'),
    require('../../assets/images/partnerBigImgs/BSN.png'),
    require('../../assets/images/partnerBigImgs/CCIA.png'),
    require('../../assets/images/partnerBigImgs/NBIA.png')
];
class AndChain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = (e) => {
        this.setState({
            // visible: false
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    toOtherPage = (pathname) => {
        this.props.history.push({pathname: pathname});
    };

    render() {
        let {isMobile} = this.state;
        return (
            <div
                className={`andChain ${isMobile ? 'mobileAndChain' : ''}`}
                style={{minWidth: isMobile ? '' : '1200px'}}>
                {/* 头部*/}
                <div className={'andChainSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        {
                            isMobile ?
                                <div className={'compassProductName'}>AndChain<br/>区块链底层平台</div>
                                :
                                <div className={'compassProductName'}>AndChain区块链底层平台</div>
                        }
                        <div className={'compassProductDescribe'}>企业级区块链开发服务，无障碍对接上层业务场景</div>
                        <Button className={'consultButton'}
                                onClick={this.toOtherPage.bind(this, '/programAns/andChain')}
                                style={{height: '.41rem'}}>
                            业务咨询
                        </Button>
                    </div>
                </div>
                <Content className="site-layout box-content">
                    <div className="firstSection">
                        <div className="headerTitle">
                            成熟的区块链底层技术
                        </div>
                        <div className="subTitle">
                            安全、高效、灵活的区块链基础设施，包括权限管理、密码学、账本、P2P网络、交易与共识等核心模块
                        </div>
                        <div style={{paddingTop: '0.1rem'}}>
                            <ShowBoxCom showData={DataTechnologArr} titleHeight={'245px'}/>
                        </div>
                    </div>

                    <div className="firstSection secSection">
                        <div className="headerTitle">
                            快速构建区块链服务
                        </div>
                        <div className="subTitle">
                            提高区块链服务构建速度，缩短整体业务实现周期
                        </div>

                        <CommonLeftDrawer DataFastService={DataFastService}
                                          rightImg={fastData}/>
                    </div>

                    <div className="firstSection thirdSection">
                        <div className="headerTitle">
                            私有化部署能力
                        </div>
                        <div className="subTitle">
                            提供定制实施与开发服务，充分满足企业差异化需求
                        </div>

                        <div className="boxContent privateContent">
                            {
                                DataPrivate.map((item, index) => {
                                    return <div className="privateBox">
                                        <div className="titleName">{item.name}</div>
                                        <p className="quare"/>
                                        <div className="detail">
                                            {item.content}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className="firstSection forthSection">
                        <div className="headerTitle">
                            应用场景
                        </div>
                        <div className="subTitle">
                            全面覆盖区块链相关业务场景
                        </div>
                        <HighBoxCom showData={DataScenariosgArr}/>
                    </div>
                </Content>
                <CommonBottomBox partnerTitle = '企业级区块链开发服务，无障碍对接上层业务场景'
                                 partnerImages = {partnerImages} />
            </div>
        );
    }
}

export default AndChain;
