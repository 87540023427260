import React, { Component } from "react";
import { Carousel, WingBlank } from 'antd-mobile';
import "./about.scss";
import './mobileAbout.scss';
import { Layout, Menu, Row, Col } from "antd";
import intrPNG from '../../assets/images/about/intr.png';
import icon1PNG from '../../assets/images/about/icon1.png';
import icon2PNG from '../../assets/images/about/icon2.png';
import icon3PNG from '../../assets/images/about/icon3.png';

import location from '../../assets/images/about/location.png';
import phone from '../../assets/images/about/phone.png';
import email from '../../assets/images/about/email.png';

import { isMobile } from '../../utils/util';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types'
const { Content } = Layout;
const cultureInfo = [
    { title: '使命', content: '促进区块链技术与业务场景的深度结合，与合作伙伴共同构建数据互通、业务协同、信任传递的商业生态。', img: icon1PNG },
    { title: '愿景', content: '成为国内专业、领先的区块链解决方案服务商，获得社会、客户和员工的认同。', img: icon2PNG },
    { title: '价值观', content: '专业、拼搏、诚信、创新', img: icon3PNG }
]
const partnerInfo = ['partner1.png', 'partner2.png', 'partner3.png', 'partner4.png', 'partner5.png', 'partner6.png', 'partner7.png', 'partner8.png', 'partner9.png', 'partner10.png', 'partner11.png', 'partner12.png', 'partner14.png', 'partner13.png', 'partner15.png', 'partner16.png']

const GRADE_ARR = [
    '第四届中国区块链开发大赛（三等奖）', '2020数字中国创新大赛区块链赛道总决赛（三等奖）',
    '2020长三角（上海）区块链应用创新大赛（三等奖）', '2020金鸡湖SIP商务区创新创新大赛金融信创专场',
    '2020中国国际智博会区块链应用创新大赛（商业前景奖）', '2018中国软件谷雨花台高新区知识产权成果创新大赛（优秀奖）',
    '2020线上中国国际智能产业博览会区块链应用创新大赛（优胜奖）', '2020金鸡现代服务业峰会SIP商务区创新大赛总决赛（优胜奖）',
    '2019高层次人才引进计划'
];
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            contentClass: "compass-tab",
            height: 0,
            imglist: ["1.png", "2.png", "3.png", "4.png"],
            left: -317,
            selectKey: this.props.match.params.type ? this.props.match.params.type : '1',
            flag: 0,
            imgHeight: 210
        };
        this.windowOnScroll();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.type !== this.props.match.params.type) {
            this.setState({
                selectKey: nextProps.match.params.type
            }, () => {
                this.scrollToPoint(nextProps.match.params.type);
            })
        }
    }



    componentDidMount() {
        let type = this.props.match.params.type || '';
        this.setState({
            selectKey: type
        }, () => {
            this.scrollToPoint(type);

        })

        setTimeout(() => {
            this.setState({
                imglist: ["1.png", "2.png", "3.png", "4.png"]
            });
        }, 100);
    }

    scrollToPoint = (type) => {
        switch (type) {
            case '1':
                window.scrollTo({ top: 435, behavior: "smooth" })
                break;
            case '2':
                window.scrollTo({ top: 860, behavior: "smooth" })
                break;
            case '3':
                window.scrollTo({ top: 1410, behavior: "smooth" })
                break;
            case '4':
                window.scrollTo({ top: 2060, behavior: "smooth" })
                break;
            case '5':
                window.scrollTo({ top: 2855, behavior: "smooth" })
                break;
            case '6':
                window.scrollTo({ top: 3400, behavior: "smooth" })
                break;
            default:
                window.scrollTo({ top: 0, behavior: "smooth" })
                break;
        }
    }

    Queue = (imagelist, item) => {
        if (item == "R") {
            let last = imagelist[imagelist.length - 1];
            for (var i = imagelist.length - 1; i > 0; i--) {
                imagelist[i] = imagelist[i - 1];
            }
            imagelist[0] = last;
        }
        if (item == "L") {
            let first = imagelist[0];
            for (var i = 0; i < imagelist.length - 1; i++) {
                imagelist[i] = imagelist[i + 1];
            }
            imagelist[imagelist.length - 1] = first;
        }
        return imagelist;
    };

    windowOnScroll() {
        let _this = this;
        window.onscroll = function () {
            //获取滚动条滚动的距离
            let h = document.documentElement.scrollTop;
            _this.setState({
                height: h,
            });
            if (h >= 425) {
                _this.setState(
                    {
                        contentClass: "compass-tab-fixed",
                    },
                    () => {
                        if (h >= 425 && h < 860) {
                            _this.setState({
                                selectKey: "1",
                            });
                        } else if (h >= 860 && h < 1410) {
                            _this.setState({
                                selectKey: "2",
                            });
                        } else if (h >= 1410 && h < 2060) {
                            _this.setState({
                                selectKey: "3",
                            });
                        } else if (h >= 2060 && h < 2855) {
                            _this.setState({
                                selectKey: "4",
                            });
                        } else if (h >= 2855 && h < 3400) {
                            _this.setState({
                                selectKey: "5",
                            });
                        } else {
                            _this.setState({
                                selectKey: "6",
                            });
                        }
                    }
                );
            } else {
                _this.setState({
                    contentClass: "compass-tab",
                });
            }
        };
    }

    leftClick = () => {
        let { left, imglist, flag } = this.state;
        // this.Queue(imglist);
        // imglist.push(imglist[0]);
        //debugger;
        //flag=flag+1;
        //left -= 317;
        //if (flag % 2 == 0) {
        imglist = this.Queue(imglist, "L");
        //}
        this.setState({
            left,
            imglist,
            //flag
        });
    };
    rightClick = () => {
        let { left, imglist, flag } = this.state;
        // imglist.unshift(imglist[3])
        //debugger;
        //flag=flag+1;
        //left += 317;
        //if (flag % 2 == 0) {
        imglist = this.Queue(imglist, "R");
        //}
        //left += 317;
        this.setState({
            left,
            imglist,
            //flag
        });
    };

    render() {
        let { imglist, left, contentClass, selectKey, isMobile } = this.state;
        return (
            <Layout className={`about ${isMobile ? 'mobileAbout' : ''}`}>
                <div className={'aboutSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'compassProductName'}>安链科技</div>
                        <div className={'compassProductDescribe'}>专业、拼搏、诚信、创新</div>
                    </div>
                </div>
                <Content className="site-layout box-content">
                    {
                        !isMobile && <Menu
                            mode="horizontal"
                            className={contentClass}
                            selectedKeys={[selectKey]}
                        >
                            <Menu.Item key="1">
                                <a onClick={() => window.scrollTo({ top: 435, behavior: "smooth" })}>公司介绍</a>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <a onClick={() => window.scrollTo({ top: 860, behavior: "smooth" })}>企业文化</a>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <a onClick={() => window.scrollTo({ top: 1410, behavior: "smooth" })}>团队风采</a>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <a onClick={() => window.scrollTo({ top: 2060, behavior: "smooth" })}>合作伙伴</a>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <a onClick={() => window.scrollTo({ top: 2855, behavior: "smooth" })}>荣誉资质</a>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <a onClick={() => window.scrollTo({ top: 3400, behavior: "smooth" })}>联系方式</a>
                            </Menu.Item>
                        </Menu>
                    }
                    <div className="intr" id="gsjs">
                        <div className="top">
                            <div className="border"></div>
                            <div className="title">公司介绍</div>
                        </div>
                        <div className="contentBox">
                            <div className="intr-content">
                                南京安链数据科技有限公司于2017年2月在南京成立，目标成为国内专业、领先的区块链公司。 公司目前研发方向主要为区块链、大数据分析相关业务方向。
                                <br />
                                <br />
                                公司核心团队来自国内外知名高校，包括北京大学、复旦大学、浙江大学、南京大学、武汉大学、东南大学等。基于区块链底层平台，公司已开发出物流、金融、溯源、存证等多个场景应用，大幅降低了区块链应用层开发成本，积累了丰富的业务实践经验。
                                <br />
                                <br />
                                公司客户已拓展至金融、物流、保险、安全、生物医药等多个领域，通过坚实的技术能力对接业务场景，为客户创造价值。
                            </div>
                            <div className="imgBox">
                                <div className="intr-img">
                                    <img src={intrPNG} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="culture" id="qywh">
                        <div className="box">
                            <div className="top">
                                <div className="border"></div>
                                <div className="title">企业文化</div>
                            </div>
                            <div className="contentBox">
                                {
                                    cultureInfo.map((item, index) => {
                                        return <div className="itemBox">
                                            <div className="top">
                                                <img src={item.img} />
                                            </div>
                                            <div className="bottom">
                                                <div>{item.title}</div>
                                                <p >{item.content}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="picture">
                        <div className="topBox">
                            <div className="top">
                                <div className="border"></div>
                                <div className="title">团队风采</div>
                            </div>
                        </div>
                        {
                            !isMobile ? <div className="contentBox">
                                <div className="switchPic">
                                    <img
                                        className="left"
                                        src={require("../../assets/images/home/leftPath.png")}
                                        onClick={this.leftClick}
                                    />
                                    <img
                                        className="right"
                                        src={require("../../assets/images/home/rightPath.png")}
                                        onClick={this.rightClick}
                                    />
                                </div>
                                <ul className="picture-ul" style={{ marginLeft: left }}>
                                    {imglist.map((item, index) => (
                                        <li>
                                            <img src={require(`../../assets/images/about/${item}`)} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                                :
                                <WingBlank>
                                    <Carousel className="space-carousel"
                                        frameOverflow="visible"
                                        cellSpacing={10}
                                        slideWidth={0.8}
                                        autoplay
                                        infinite
                                        // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                                        afterChange={index => this.setState({ slideIndex: index })}
                                    >
                                        {imglist.map((val, index) => (
                                            <a
                                                key={val}
                                                style={{
                                                    display: 'block',
                                                    position: 'relative',
                                                    // top: this.state.slideIndex === index ? -10 : 0,
                                                    height: this.state.imgHeight,
                                                    // boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.2)',
                                                }}
                                            >
                                                <img
                                                    src={require(`../../assets/images/about/${val}`)}
                                                    alt=""
                                                    style={{ width: '100%', height: '211px' }}
                                                    onLoad={() => {
                                                        window.dispatchEvent(new Event('resize'));
                                                        this.setState({ imgHeight: 'auto' });
                                                    }}
                                                />
                                            </a>
                                        ))}
                                    </Carousel>
                                </WingBlank>
                        }
                    </div>
                    <div className="partner" id="hzhb">
                        <div className="box">
                            <div className="top">
                                <div className="border"></div>
                                <div className="title">合作伙伴</div>
                            </div>
                            {
                                !isMobile ? <React.Fragment>
                                    <div className="contentBox">
                                        {partnerInfo.slice(0, 4).map(item => {
                                            return <div className="rowBox">
                                                <img src={require(`../../assets/images/about/${item}`)} />
                                            </div>
                                        })
                                        }
                                    </div>
                                    <div className="contentBox">
                                        {partnerInfo.slice(4, 8).map(item => {
                                            return <div className="rowBox">
                                                <img src={require(`../../assets/images/about/${item}`)} />
                                            </div>
                                        })
                                        }
                                    </div>
                                    <div className="contentBox">
                                        {partnerInfo.slice(8, 12).map(item => {
                                            return <div className="rowBox">
                                                <img src={require(`../../assets/images/about/${item}`)} />
                                            </div>
                                        })
                                        }
                                    </div>
                                    <div className="contentBox">
                                        {partnerInfo.slice(12, 16).map(item => {
                                            return <div className="rowBox">
                                                <img src={require(`../../assets/images/about/${item}`)} />
                                            </div>
                                        })
                                        }
                                    </div>
                                </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="contentBox">
                                            {partnerInfo.slice(0, 2).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(2, 4).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(4, 6).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(6, 8).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(8, 10).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(10, 12).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(12, 14).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                        <div className="contentBox">
                                            {partnerInfo.slice(14, 16).map(item => {
                                                return <div className="rowBox">
                                                    <img src={require(`../../assets/images/about/${item}`)} />
                                                </div>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="qual" id="ryzz">
                        <div className="box">
                            <div className="top">
                                <div className="border"></div>
                                <div className="title">资质认证</div>
                            </div>
                            {
                                !isMobile && <React.Fragment>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }} span={6}>国家高新技术企业</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>ISO9001</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>FISCO会员单位</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>双软企业</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }} span={6}>CMMI3</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>ISO20000</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>BSN合格开发者 </Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>科技型中小企业</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }} span={6}>江苏省民营企业</Col>
                                        <Col style={{ textAlign: 'left' }} span={6}>ISO27001</Col>
                                        {/*<Col style={{textAlign: 'left'}} span={6}>潜力型小微企业</Col>*/}
                                    </Row>
                                </React.Fragment>
                            }
                            {
                                isMobile && <div className="content">
                                    <div>国家高新技术企业</div>
                                    <div>科技型中小企业</div>
                                    <div>江苏省民营企业</div>
                                    <div>双软企业</div>
                                    <div>CMMI3</div>
                                    <div>ISO9001</div>
                                    <div>ISO20000</div>
                                    <div>ISO27001</div>
                                    <div>FISCO会员单位</div>
                                    <div>BSN合格开发者</div>
                                </div>
                            }

                            <div className="top marginTop">
                                <div className="border"></div>
                                <div className="title">荣誉奖项</div>
                            </div>
                            <div className="titleContent">
                                {
                                    GRADE_ARR.map((item) => {
                                        return <div className="gradeTitle">
                                            {item}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="contact" id="lxfs" style={{ marginTop: 30 }}>
                        <div className="box">
                            <div className="top">
                                <div className="border"></div>
                                <div className="title">联系方式</div>
                            </div>
                            {
                                !isMobile && <div className="contentBox">
                                    <div className="left">
                                        <div>地址<img src={location} /></div>
                                        <p>南京市雨花台区软件大道106号2栋602室</p>
                                        <div>电话<img src={phone} /></div>
                                        <p>025-83370906</p>
                                        <div>邮箱<img src={email} /></div>
                                        <p>marketing@andlinks.com</p>
                                        <img style={{ width: '1.34rem', height: '1.34rem' }}
                                            src={require("../../assets/images/about/Bitmap.png")} />
                                        <p style={{ margin: '10px 0 0 14px' }}>扫码关注公众号</p>
                                    </div>
                                    <div className="right">
                                        <img src={require("../../assets/images/about/map.jpg")} />
                                    </div>
                                </div>
                            }
                            {
                                isMobile && <div className="content">
                                    <div className="top">
                                        <div className="left">
                                            <div>地址</div>
                                            <p>南京市雨花台区软件大道106号2栋602室</p>
                                            <div>电话</div>
                                            <p>025-83370906</p>
                                            <div>邮箱</div>
                                            <p>marketing@andlinks.com</p>
                                        </div>
                                        <div className="right">
                                            <img style={{ marginTop: '16px' }}
                                                src={require("../../assets/images/about/Bitmap.png")} />
                                            <p style={{ margin: '10px 0 0 14px' }}>扫码关注公众号</p>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <img src={require("../../assets/images/about/map.jpg")} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default withRouter(About);
