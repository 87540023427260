import React, { Component } from "react";
import "./news.scss";
import './mobileNews.scss';
import { Layout, Pagination, Spin, Tabs } from "antd";
import { Link } from "react-router-dom";
import { getNewsList } from "../../services/news";
import { isMobile, isNumber, overtopOmit } from '../../utils/util';
const { Content } = Layout;
const { TabPane } = Tabs;
class News extends Component {
    constructor(props) {
        super(props);
        const tabs = [
            { name: "全部新闻", index: '' },
            { name: "成长之路", index: '1' },
            { name: "行业助力", index: '2' },
            { name: "技术空间", index: '3' },
        ];
        this.state = {
            isMobile: isMobile(),
            tabs,
            type: this.props.match.params.type ? this.props.match.params.type : '',
            curpage: 1,
            pagesize: 10,
            total: 0,
            data: [],
            loading: false
        };
    }

    componentDidMount() {
        this.getNewsList();
    }

    // 分页切换
    onPaginationChange = (page) => {
        this.setState({ curpage: page, }, () => {
            this.getNewsList();
        }
        );
    };

    yearDeal = (data) => {
        let arr = data.split("-");
        return arr[0];
    };

    mounthDeal = (data) => {
        let arr = data.split("-");
        return arr[1];
    };

    dayDeal = (data) => {
        let arr = data.split("-");
        return arr[2];
    };

    getNewsList = () => {
        this.setState({ loading: true }, () => {
            let { curpage, pagesize, type } = this.state;
            type = type === 0 ? '' : type;
            getNewsList({ status: 1, curpage: curpage, pagesize: pagesize, type }).then((res) => {
                if (res.result === 0) {
                    this.setState(
                        {
                            data: res.data,
                            total: res.total,
                            loading: false,
                        });
                }
            })
                .catch(() => {
                    this.setState({
                        data: [],
                        loading: false,
                    });
                });
        })
    }

    callback = (key) => {
        this.setState({ type: key, curpage: 1 }, () => {
            this.getNewsList();
        })
    }

    render() {
        const { tabs, data, loading, total, curpage, pagesize, isMobile, type } = this.state;
        let origin = window.location.origin === 'http://localhost:3000' ? 'http://192.168.1.224:10121' : window.location.origin + '/oa';
        return (
            <Layout className={`news ${isMobile ? 'mobileNews' : ''}`} name="all">
                {/* 头部*/}
                <div className={'newsSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'compassProductName'}>新闻中心</div>
                        <div className={'compassProductDescribe'}>公司最新动态发布平台</div>
                    </div>
                </div>
                <Content className="site-layout box-content">
                    <Tabs defaultActiveKey={type} onChange={this.callback}>
                        {
                            tabs.map(item => {
                                return <TabPane tab={item.name} key={item.index}>
                                    <Spin spinning={loading}>
                                        <div>
                                            {!isMobile && data.map((item) => (
                                                <div className="news-list">
                                                    <div className="imgBox">
                                                        <img
                                                            src={`${origin}/save-images/${item.cover}`}
                                                        />
                                                    </div>
                                                    <div className="news-list-item2">
                                                        <h2>
                                                            {this.mounthDeal(item.pubdate)}/
                                                            {this.dayDeal(item.pubdate)}
                                                        </h2>
                                                        <p>{this.yearDeal(item.pubdate)}</p>
                                                    </div>
                                                    <div className="news-list-item3">
                                                        <Link to={"/newsDetails/" + item.newsid} ><h3>{item.title}</h3></Link>
                                                        <p>{item.summary}</p>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                            {
                                                isMobile && data.map((item) => (
                                                    <div className="mobile-news-list">
                                                        <div className="title"><Link to={"/newsDetails/" + item.newsid} >{item.title}</Link></div>
                                                        <div className="content">
                                                            <span>{overtopOmit(item.summary, 40)}</span>
                                                            <img src={`${origin}/save-images/${item.cover}`} />
                                                        </div>
                                                        <div className="date">
                                                            <span>
                                                                {this.mounthDeal(item.pubdate)}/
                                                            {this.dayDeal(item.pubdate)}
                                                            </span>
                                                            <span>{this.yearDeal(item.pubdate)}</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div>
                                                {total > 0 ? (
                                                    <Pagination
                                                        style={{ textAlign: "center" }}
                                                        current={curpage}
                                                        pageSize={pagesize}
                                                        total={total}
                                                        onChange={this.onPaginationChange}
                                                    />
                                                ) : (
                                                        <div style={{ marginTop: 10 }}>暂无数据</div>
                                                    )}
                                            </div>
                                        </div>
                                    </Spin>
                                </TabPane>
                            })
                        }
                    </Tabs>
                </Content>
            </Layout>
        );
    }
}

export default News;
