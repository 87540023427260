/**
 * Created by zal on 2020/11/2
 * 解决方案公共头部
 * @param  data
 * { name: String, describe: String, buttonName: String }
 */
import React from 'react';
import './commonSolutionHeader.scss';
import {Button} from 'antd';
import {isMobile} from '../../../utils/util';

function CommonSolutionHeader({data, headerOnClick}) {
    return (
        <div className={'commonSolutionHeader'}>
            <div className={'subHeaderRoot'}>
                <div className={'subHeaderName'}>{data?.name || ''}</div>
                <div className={'subHeaderDescribe'}>{data?.describe || ''}</div>
                {
                    data?.name === '区块链存证' && !isMobile() ?
                        <Button style={{
                            height: '.41rem',
                            marginRight: '20px',
                            color: '#ffffff',
                            background: 'transparent'
                        }} onClick={
                            () => window.open('http://cloud.andlinks.com')
                        }>立即体验</Button>
                        : ''
                }
                <Button
                    onClick={headerOnClick}
                    style={{height: '.41rem'}}
                    type="primary"
                >
                    {data?.buttonName || '业务咨询'}
                </Button>
            </div>
        </div>
    );
}

export default CommonSolutionHeader;
