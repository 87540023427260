import React, {Component} from 'react';
import "./commonValueBox.scss";
import "./mobileCommonValueBox.scss";
import {isMobile} from "../../utils/util";
import * as antdMobile from 'antd-mobile';

const AntdMobileCarousel = antdMobile.Carousel;

class CommonValueBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    renderDataValueItem = () => {
        let {DataValue, titleHeight} = this.props;
        return DataValue.map((item, index) => {
            return <div
                key={item.name}
                className="valueBox"
                style={{height: titleHeight ? titleHeight : '380px'}}>
                <img src={item.img}
                     className="valueImg" alt=""/>
                <div className="introduceTitle">
                    <div className="subName">{item.name}</div>
                    <div className="line"></div>
                    <div className="bottomTitle">{item.content}</div>
                </div>
            </div>
        })
    }

    render() {
        let {isMobile} = this.state;
        return (
            <div
                className={`valueContent ${isMobile ? 'mobileValueContent' : ''}`}
                style={{minWidth: isMobile ? '' : '1160px'}}>
                {
                    isMobile ?
                        <AntdMobileCarousel
                            dots={true}
                            // infinite
                            frameOverflow="visible"
                            cellSpacing={20}
                            slideWidth={0.8}
                        >
                            {this.renderDataValueItem()}
                        </AntdMobileCarousel>
                        :
                        this.renderDataValueItem()
                }
            </div>
        );
    }
}

export default CommonValueBox;