/**
 * 核心产品-Compass智能图分析平台
 */
import React, { Component, Fragment } from 'react';
import './compassProduct.scss';
import './mobileCompass.scss';
import { Button } from 'antd';
import RichApplicationScenarios from '../../components/richApplicationScenarios/RichApplicationScenarios';
import ShowBoxCom from '../../components/showBoxCom/ShowBoxCom';
import ApplicationScenarios from './applicationScenarios/ApplicationScenarios';
import CommonBottomBox from '../../components/commonBottomBox/CommonBottomBox';

import pdTopLeftIcon from '../../assets/images/compass/pdTopLeftIcon.png';
import wavePointPNG from '../../assets/images/compass/wavePoint.png';
import pdImgPNG from '../../assets/images/compass/pdImg.png';
import ras1PNG from '../../assets/images/compass/ras1.png';
import ras2PNG from '../../assets/images/compass/ras2.png';
import ras3PNG from '../../assets/images/compass/ras3.png';
import ras4PNG from '../../assets/images/compass/ras4.png';
import ras5PNG from '../../assets/images/compass/ras5.png';
import rasBg1PNG from '../../assets/images/compass/rasBg1.png';
import rasBg2PNG from '../../assets/images/compass/rasBg2.png';
import rasBg3PNG from '../../assets/images/compass/rasBg3.png';
import rasBg4PNG from '../../assets/images/compass/rasBg4.png';
import rasBg5PNG from '../../assets/images/compass/rasBg5.png';
import boxCom1PNG from '../../assets/images/compass/boxCom1.png';
import boxCom2PNG from '../../assets/images/compass/boxCom2.png';
import boxCom3PNG from '../../assets/images/compass/boxCom3.png';
import boxCom4PNG from '../../assets/images/compass/boxCom4.png';
import { isMobile } from '../../utils/util';

const partnerImages = [
    require('../../assets/images/partnerBigImgs/ZGBQ.png'),
    require('../../assets/images/partnerBigImgs/LJGCDX.png')
];

class Compass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            richApplicationScenariosData: [
                {
                    name: '分析模型',
                    icon: ras1PNG,
                    activeIcon: ras1PNG,
                    img: rasBg1PNG,
                    synopsis: '分析模型提升分析效率',
                    describe: '通过实际业务场景积累模型经验，结合AI算法开发出分析模型。在其支撑下可以实现自动化分析与辅助决策，大幅提高分析效率和准确性。典型的分析模型包括话单模型、同行人模型、资金交易模型等。'
                },
                {
                    name: '路径探寻',
                    icon: ras2PNG,
                    activeIcon: ras2PNG,
                    img: rasBg2PNG,
                    synopsis: '寻找实体间的关联路径',
                    describe: '自动寻找两个节点间可能的所有路径以及包含多个节点的连通路径。使用路径探寻功能可以发现实体之间是如何产生关联的，中间经过了哪些其他实体的传递，最终将这些实体全部联系起来。'
                },
                {
                    name: '条件筛选',
                    icon: ras3PNG,
                    activeIcon: ras3PNG,
                    img: rasBg3PNG,
                    synopsis: '快速开展针对性分析',
                    describe: '借助条件筛选功能，分析师能够根据实际需求选择相应的数据，屏蔽其他数据的干扰，从而使关联分析谱图更加简洁直观，突出重点关系。'
                },
                { name: '数据全貌', icon: ras4PNG, activeIcon: ras4PNG, img: rasBg4PNG, synopsis: '数据整体概况展示', describe: '针对大数据量的分析，引入数据全貌功能，对全量数据进行预先统计和挖掘，包括整体结构和分布，存在多少连通子图，有哪些节点可以聚成一个社群，哪些节点的中心地位比较高等。' },
                { name: '图计算', icon: ras5PNG, activeIcon: ras5PNG, img: rasBg5PNG, synopsis: '指标计算与子图匹配', describe: '指标配置功能能够计算节点的一度关联数、总关系数等加权统计值。子图匹配帮助分析者从全图数据中匹配出符合一定格式的子图数据，例如循环投资的子图，帮助发现频繁出现的模式。' }
            ],
            showBoxComData: [
                { name: 'B/S架构', image: boxCom1PNG, content: '有效降低资源成本，实现在线协同分析。服务端支持横向扩展集群规模和分布式索引，性能线性增长。' },
                { name: '人机交互', image: boxCom2PNG, content: '满足分析师探索式的分析需求，让人的思考与机器的计算同步，充分体现人机交互的强大力量。' },
                { name: '自动化分析', image: boxCom3PNG, content: '通过分析模型实现同类型数据的自动化分析，AI辅助决策，提高分析效率。' },
                { name: '图计算能力', image: boxCom4PNG, content: '基于图算法和底层计算能力实现指标计算和子图匹配等功能，帮助用户从海量数据中发现典型行为模式。' }
            ]
        };
    }

    toOtherPage =(pathname) => {
        this.props.history.push({ pathname: pathname });
    };

    toOtherSystem =(url) => {
        window.open(url);
    };

    render() {
        let { isMobile, richApplicationScenariosData, showBoxComData } = this.state;
        return (
            <div className={`compassProduct ${isMobile ? 'mobileCompass' : ''}`} style={{ minWidth: isMobile ? '' : '1200px' }}>
                {/* 头部*/}
                <div className={'compassProductSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'compassProductName'}>{'Compass\n智能图分析平台'}</div>
                        <div className={'compassProductDescribe'}>为挖掘数据关联价值量身定制的可视化分析平台</div>
                        {
                            isMobile ? '' :
                                <Button
                                    onClick={this.toOtherSystem.bind(this, 'http://compass.andlinks.com')}
                                    style={{ height: '.41rem' }} type="primary">立即体验</Button>
                        }
                        <Button
                            onClick={this.toOtherPage.bind(this, '/programAns/compass')}
                            className={'consultButton'} style={{ height: '.41rem' }}>业务咨询</Button>
                    </div>
                </div>
                {/* 产品优势*/}
                <div className={'pdRoot'}>
                    <div className={'pd'}>
                        <div className={'pdTitle'}>产品描述</div>
                        <div className={'pdTopLeftIcon'}><img src={pdTopLeftIcon} alt=""/></div>
                        {
                            isMobile ? <Fragment>
                                    <div className={'pdCenter'}>
                                        <span>在当代实际业务需求中，数据往往不是孤立的，在分析实体的过程中通常需要考虑实体之间的关系，包括人、企业、机构、组织之间的关系等。传统的关系型数据库在描述实体间关系的能力上十分有限，而通过图数据库可以直接以节点和边的形式存储实体和关系，利用一系列的关联性探索分析挖掘复杂多源数据下隐藏的相关性。</span><br/>
                                        <span>在此背景下，Compass智能图分析平台定位于为各领域的调查人员、数据分析师提供一个综合性的数据可视化分析平台，用于各类专项调查以及发现工作。基于Compass，分析师人员可以将掌握的各种数据资源导入平台，构建适用于自身业务的关联图谱。Compass提供的丰富的可视化展示、交互查询，辅以多样的分析模型和图挖掘算法，能够洞察数据间的关联关系，帮助执法/监管部门和金融机构快速识别洗钱、欺诈、危害公共安全等活动，亦能够提升企业的风险预警能力和产品研发能力。应用领域包括国防、政务、公安、金融、机械制造、生物医药等。</span>
                                        <div className={'pdCenterImgRoot'}>
                                            <img className={'wavePointPNG'} src={wavePointPNG} alt=""/>
                                            <img className={'rightBottomIcon'} src={pdTopLeftIcon} alt=""/>
                                        </div>
                                    </div>
                                    <div className={'pdBottom'}>
                                        <img className={'pdImg'} src={pdImgPNG} alt=""/>
                                    </div>
                            </Fragment> :
                                <Fragment>
                                    <div className={'pdCenter'}>
                                        <span>在当代实际业务需求中，数据往往不是孤立的，在分析实体的过程中通常需要考虑实体之间的关系，包括人、企业、机构、组织之间的关系等。传统的关系型数据库在描述实体间关系的能力上十分有限，而通过图数据库可以直接以节点和边的形式存储实体和关系，利用一系列的关联性探索分析挖掘复杂多源数据下隐藏的相关性。</span>
                                        <img src={wavePointPNG} alt=""/>
                                    </div>
                                    <div className={'pdBottom'}>
                                        <img className={'pdImg'} src={pdImgPNG} alt=""/>
                                        <div
                                            className={'pdBottomText'}>在此背景下，Compass智能图分析平台定位于为各领域的调查人员、数据分析师提供一个综合性的数据可视化分析平台，用于各类专项调查以及发现工作。基于Compass，分析师人员可以将掌握的各种数据资源导入平台，构建适用于自身业务的关联图谱。Compass提供的丰富的可视化展示、交互查询，辅以多样的分析模型和图挖掘算法，能够洞察数据间的关联关系，帮助执法/监管部门和金融机构快速识别洗钱、欺诈、危害公共安全等活动，亦能够提升企业的风险预警能力和产品研发能力。应用领域包括国防、政务、公安、金融、机械制造、生物医药等。
                                        </div>
                                        <img className={'rightBottomIcon'} src={pdTopLeftIcon} alt=""/>
                                    </div>
                                </Fragment>
                        }
                    </div>
                </div>
                {/* 产品功能*/}
                <RichApplicationScenarios name={'产品功能'} borderRadius={'4px'} data={richApplicationScenariosData}/>
                {/* 产品优势*/}
                <div className={'productAdvantages'}>
                    <div className={'productAdvantagesName'}>产品优势</div>
                    <ShowBoxCom showData={showBoxComData}/>
                </div>
                {/* 应用领域*/}
                <ApplicationScenarios />
                <CommonBottomBox partnerTitle={'为挖掘数据关联价值量身定制的可视化分析平台'}
                                 partnerImages = {partnerImages}/>
            </div>
        );
    }
}

export default Compass;
