/**
 * Created by zal on 2020/11/2
 * 解决方案-业务价值
 * @param  data
 * { name: String, showBoxComData: ShowBoxComData }
 */
import React from 'react';
import './commonDivRoot.scss';
import ShowBoxCom from '../../showBoxCom/ShowBoxCom';

function CommonBusinessValue ({ data }) {
    return (
        <div className={'commonDivRoot'}>
            <div className={'commonDiv'}>
                <div className={'commonTitle'}>{data?.name || '业务价值'}</div>
            </div>
            <ShowBoxCom showData={data?.showBoxComData || []} titleHeight={data?.titleHeight} />
        </div>
    );
}

export default CommonBusinessValue;
