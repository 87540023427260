import React, {Component} from 'react';
import {isMobile} from '../../utils/util';
import "./showBoxCom.scss";
import "./mobileShowBoxCom.scss";

class ShowBoxCom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }


    render() {
        let {showData, titleHeight} = this.props;
        let {isMobile} = this.state;
        return (
            <div
                className={`showBoxCom ${isMobile ? 'mobileShowBoxCom' : ''}`}
                style={{minWidth: isMobile ? '' : '1160px'}}>
                {
                    showData && showData.map((item, index) => {
                        return <div className="box"
                                    style={{ width: isMobile ? '100%' : `calc((100% - 1.4rem ) / ${showData.length})` }}
                                    >
                            <img src={item.image} className="topImg"></img>
                            <div className="botContent"
                                 style={{ height: titleHeight ? titleHeight : '1.98rem'}}
                                >
                                <div className="titleName">{item.name}</div>
                                <div className="titleContent">
                                    {item.content}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        );
    }
}

export default ShowBoxCom;