/**
 * 核心产品-供应链金融平台
 */
import React, { Component } from "react";
import "./andFinance.scss";
import "./mobileAndFinance.scss";
import { Layout, Button} from "antd";
import RichApplicationScenarios from '../../components/richApplicationScenarios/RichApplicationScenarios';
import  receivables from '../../assets/images/andFinance/receivables.png';   // 应收账款融资
import  receivablesDetail from '../../assets/images/andFinance/receivablesDetail.png';
import  numberWarehouse from '../../assets/images/andFinance/numberWarehouse.png' ;  // 数字仓单
import  numberWarehouseDetail from '../../assets/images/andFinance/numberWarehouseDetail.png';
import  property from '../../assets/images/andFinance/property.png';   // 资产证券化
import  propertyDetail from '../../assets/images/andFinance/propertyDetail.png';
import  trade from '../../assets/images/andFinance/trade.png';   // 跨境贸易
import  tradeDetail from '../../assets/images/andFinance/tradeDetail.png';
import  painSpot from '../../assets/images/andFinance/painSpot.png'
import CommonValueBox from "../../components/commonValueBox/CommonValueBox";
import { isMobile } from '../../utils/util';
import CommonBottomBox from "../../components/commonBottomBox/CommonBottomBox";
const { Content } = Layout;

const partnerImages = [
    require('../../assets/images/partnerBigImgs/HKJT.png'),
    require('../../assets/images/partnerBigImgs/SMGYL.png')
];

class AndFinance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            sceneArr:[
                { name: '应收账款融资', icon: receivables, activeIcon: receivables, img: receivablesDetail, synopsis: 'Accounts receivable financing', describe: '利用应收账款电子凭证的拆分、流转、融资，将供应链核心企业的信任传递到上游多级供应商，借助银行、保理等金融机构打造供应链金融产业生态闭环，解决中小微企业融资难的问题，促进各业务方互利合作，推动生态健康发展。' },
                { name: '数字仓单', icon: numberWarehouse, activeIcon: numberWarehouse, img: numberWarehouseDetail, synopsis: 'Digital warehouse receipts', describe: '库存成本在整个供应链运营成本中占比超过30%，仓单的无重复抵押、真实性、货物监控是仓库管理的关键，数据全面、可信是银行审批贷款的关键。\n\n\r区块链系统联合仓储、物流、保险和监管机构，以电子凭证代替纸质票据，通过IoT设备对货物进出库进行全流程监管，数据全程上链，从而降低仓库管理造假作弊风险。' },
                { name: '资产证券化', icon: property, activeIcon: property, img: propertyDetail, synopsis: 'Asset-backed securitization', describe: '传统的资产证券化业务中，券商、律所、银行等多机构系统独立，数据获取依赖纸质单据和人工操作，成本高、周期长、准确性低，业务协同难度大。\n\n\r区块链技术的引入促进了票据电子化，分布式存储的模式使底层资产动态透明化，最大化实现穿透式监管。业务流程链上进行，基于智能合约保障操作规范化、自动化，减小人工操作可能带来的损失。' },
                { name: '跨境贸易', icon: trade, activeIcon: trade, img: tradeDetail, synopsis: 'Cross-boarder trading', describe: '对于跨境贸易场景下的中小企业，由于难以及时获取物流通关信息，且信息难以穿透，在各监管机构、金融机构之间无法自证，导致信用缺失，造成融资难。\n\n\r区块链技术与跨境贸易的结合，打破了信息不对称的壁垒，将助力企业信用体系建设，提升贸易便利化，并提升金融机构风控能力，建立支持中小企业敢贷、愿贷、能贷的长效机制。' },

            ],
            DataValue: [
                {name: '专业顾问服务', img: require('../../assets/images/andFinance/1.png'), content: '提供专业的业务咨询与运维支持，包括案例分析、运营规划和技术培训等。'},
                {name: '标准化产品', img: require('../../assets/images/andFinance/2.png'), content: '标准化的SaaS平台，低成本上链，业务逻辑快速实现，强大的区块链底层技术支持。'},
                {name: '私有化部署', img: require('../../assets/images/andFinance/3.png'), content: '针对企业的个性化需求定制开发，安全可靠的私有化部署模式充分满足企业的信息安全需求。'}
            ]
        };
    }

    headerOnClick =() => {
        this.props.history.push({ pathname: '/programAns/andFinance' });
    };

    render() {
        const { sceneArr, DataValue, isMobile } = this.state;
        return (
            <Layout className={`andFinance ${isMobile ? 'mobileAndFinance' : ''}`} style={{ minWidth: isMobile ? '' : '1200px' }}>
                <div className={'andFinanceHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'andFinanceName'}>{'AndFinance\n供应链金融平台'}</div>
                        <div className={'andFinanceDescribe'}>建立多方信任机制，实现资产的数字化、标准化与穿透式管理，稳固健全供应链生态</div>
                        <Button className={'consultButton'} onClick={this.headerOnClick} style={{ height: '.41rem' }}>业务咨询</Button>
                    </div>
                </div>
                <Content className="andFinance-content">
                    <div className="firstSection">
                        <div className="headerTitle">
                            供应链金融业务痛点
                        </div>
                        <div className="subTitle">
                            数据不可信引发“资金流、信息流、物流、商流”四流难合一，导致企业融资难、资方风控难、政府监管难
                        </div>
                        <img src={painSpot} alt="" className="painSpotImg"/>
                    </div>
                    <RichApplicationScenarios data={sceneArr} />
                    <div className="threeSection">
                        <div className="headerTitle">
                            促进业务各方价值提升
                        </div>
                        <CommonValueBox DataValue={DataValue}/>
                    </div>
                </Content >
                <CommonBottomBox partnerTitle={'建立多方信任机制，实现资产的数字化、标准化与穿透式管理，稳固健全供应链生态'}
                                 partnerImages = {partnerImages}/>
            </Layout>
        );
    }
}

export default AndFinance;
