import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
     componentDidUpdate(prevProps) {
         if (this.props.location !== prevProps.location) {
           window.scrollTo(0, 0);
           window.parent.scrollTo(0, 0)   // 手机端跳转到顶部
         }
     }
     render() {
         return this.props.children
     }
 }
    
export default withRouter(ScrollToTop);
