import qs from 'qs';
import axios from 'axios';
import { browserHistory } from 'react-router-dom';
import config from '../resources/config.dev';
import { HttpMethod } from '../constants/common';
import { getSession } from '../utils/storage';
import { isString, isBlank, isEmpty, isNotEmpty, isFormData } from './util';
import { message } from 'antd';
import pkg from '../../package.json';

const ContentType = config.ContentType;
const baseURL = pkg.proxy;

/**
 * @desc 使用axios第三方库访问后台服务器, 返回封装过后的Promise对象.
 * @param {string} url 请求的接口地址, 格式: "/xxx..."
 * @param urlType
 * @param {string} domain 跨域请求的域名地址, 如: http://www.baidu.com
 * @param {string} type HTTP请求方式, 默认GET.
 * @param {object} data 请求的数据, object对象格式
 * @param contentType
 * @param {function} onUpload 上传文件过程中的回调函数, 接收progressEvent参数.
 * @param {function} onDownload 下载文件过程中的回调函数, 接收progressEvent参数.
 * @param {function} cancel 取消请求的回调函数, 接收cancel参数, 当执行cancel()参数时请求被取消.
 * @param {number} timeout 配置请求超时时间, 为毫秒数, 默认从配置文件读取.
 * @param closeTips
 * @param {boolean} cache 是否开启缓存, 开启后同样的请求(url相同, 参数相同), 第二次请求时会直接返回缓存数据, 不会请求后台数据, 默认false.
 * @param {boolean} handleError 是否自动处理接口报错情况, 默认true.
 * @return {object} - 返回一个promise的实例对象
 */
export function HJPromise({
                              url = '',
                              urlType = null,
                              type = HttpMethod.GET,
                              data = null,
                              contentType = ContentType.JSON,
                              onUpload = null,
                              onDownload = null,
                              cancel = null,
                              timeout = config.timeout,
                              closeTips = false,
                              handleError = true
                          }) {
    let getData;
    let postData;
    let cancelToken;
    let crossDomain = false;
    if (isEmpty(url)) {
        return Promise.resolve();
    }

    if (type === HttpMethod.POST) {
        if (isNotEmpty(data)) {
            postData = data;
            if (ContentType.FORM_URLENCODED === contentType) {
                if (isNotEmpty(postData) && !isFormData(postData)) {
                    postData = qs.stringify(postData, { allowDots: true });
                }
            }
        } else {
            postData = {};
        }
    } else {
        getData = data === null ? {} : data;
    }
    if (isNotEmpty(cancel)) {
        cancelToken = new axios.CancelToken(cancel);
    }
    // if (!cache) {
    //     url += '?t=' + new Date().getTime();
    // }
    let promise = new Promise((resolve, reject) => {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['User-Token'] = getSession('User-Token');
        axios.defaults.headers.post['Content-Type'] = contentType + ';charset=UTF-8';
        axios({
            method: type,
            baseURL: config.server.baseURL,
            url: url,
            timeout: timeout,
            params: getData,
            
            // data: { ...postData,  language: getSession('language') || 'ZH' },
            data: postData,
            withCredentials: true,
            onUploadProgress: onUpload,
            onDownloadProgress: onDownload,
            cancelToken: cancelToken
        }).then((response) => {
            if (isBlank(response.data)) {
                if (handleError) {
                    message.error('网络异常, 请稍后再试');
                }
                reject(response);
            } else {
                let responseData = response.data;
                if (isString(responseData)) {
                    try {
                        responseData = JSON.parse(responseData);
                    } catch (e) {
                        try {
                            if (urlType) {
                                responseData = JSON.parse(responseData.split('=')[1].split(';')[0]);
                            }
                        } catch (e) {
                            if (handleError) {
                                message.error('数据异常');
                            }
                            reject(e);
                            return;
                        }
                    }
                }
                if (!urlType) {
                    if (responseData.result === 0) {
                        resolve(responseData);
                    } else if (responseData.result === -101001) {
                        reject(responseData);
                    } else if (responseData.result === -101002) {
                        reject(responseData);
                    } else if (responseData.result === -101003) {
                        reject(responseData);
                    } else if (responseData.result === -101004) {
                        message.error(responseData.message.zh);
                        //browserHistory.push('/login');
                        this.props.history.push('/login');
                    } else if (responseData.result === -101005) {
                        message.error(responseData.message.zh);
                        //browserHistory.push('/login');
                        this.props.history.push('/login');
                    } else {
                        reject(responseData);
                        if (handleError) {
                            if (!closeTips) {
                                if (responseData.message.zh.indexOf('ERROR') !== -1) {
                                    message.error(responseData.message.ext);
                                } else {
                                    // message.error(responseData.message.zh + responseData.message.ext);
                                    message.error(responseData.message.ext);
                                }

                            }
                        }
                    }
                } else {
                    resolve(responseData);
                }
            }
        }).catch((error) => {
            // 服务端返回的异常
            if (error.response) {
                if (handleError) {
                    if (!urlType) {
                        // Tip.error(error.response.data.msg);
                    } else {
                        // Tip.error('这不是一个有效的请求地址');
                    }
                }
                reject(error.response.data);
                // 浏览器抛出的异常, 不同浏览器可能有不同的行为
            } else {
                if (handleError) {
                    if (!urlType) {
                        setTimeout(() => {
                            // Tip.error('网络异常, 请稍后再试');
                        }, 1000);
                    }
                }
                reject(error);
            }
        });
    });
    return promise;
}

Promise.prototype.done = (onFulfilled, onRejected) => {
    this.then(onFulfilled, onRejected)
        .catch((reason) => {
            // 抛出一个全局错误
            setTimeout(() => {
                throw reason;
            }, 0);
        });
};

Promise.prototype.finally = (callback) => {
    let P = this.constructor;
    return this.then(
        value => P.resolve(callback(value)).then(() => value),
        reason => P.resolve(callback(reason)).then(() => {
            //  throw reason;
        })
    );
};
