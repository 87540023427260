/**
 * 首页
 */
import React, { Component } from 'react';
import './home.scss';
import './mobileHome.scss';
import { Carousel, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import * as antdMobile from 'antd-mobile';
import { Link } from 'react-router-dom';
import { getNews } from '../../services/news';
import { isMobile, overtopOmit } from '../../utils/util';
import CommonRightHelp from '../../components/commonRightHelp/CommonRightHelp';
import leftPNG from '../../assets/images/home/left.png';
import rightPNG from '../../assets/images/home/right.png';
import leftPath from '../../assets/images/home/leftPath.png';
import rightPath from '../../assets/images/home/rightPath.png';

import home1PNG from '../../assets/images/home/BG1.png';
import home2PNG from '../../assets/images/home/BG2.png';
import home3PNG from '../../assets/images/home/BG3.png';
import home4PNG from '../../assets/images/home/BG4.png';
import home5PNG from '../../assets/images/home/BG5.png';

import onePNG from '../../assets/images/home/one.png';
import twoPNG from '../../assets/images/home/two.png';
import threePNG from '../../assets/images/home/three.png';

import homeProductIconPNG from '../../assets/images/home/homeProductIcon.png';
import homeProductRightIconPNG from '../../assets/images/home/homeProductRightIcon.png';

const AntdMobileCarousel = antdMobile.Carousel;

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            carouselData: [
                // {
                //     name: '客加，您的客户增长专家！',
                //     url: 'https://cusplus.com',
                //     img: home5PNG,
                //     describe1: '企业微信服务商',
                //     describe2: '实现客户增长与客户精细化运营，打造长远而忠诚的客户关系'
                // },
                {
                    name: '链上世界，触手可得',
                    url: '/about',
                    img: home1PNG,
                    describe1: '区块链整体解决方案服务商',
                    describe2: '助力企业和机构快速上链，构建完整业务生态'
                },
                {
                    name: 'AndChain区块链基础平台',
                    url: '/andChain',
                    img: home2PNG,
                    describe1: '安全、高效、灵活的区块链基础设施',
                    describe2: ''
                },
                {
                    name: 'AndLogistics物流管理平台',
                    url: '/andLogistics',
                    img: home3PNG,
                    describe1: '连接物流、保险、金融机构',
                    describe2: '构建多方协作、信任传递的物流管理新模式'
                },
                {
                    name: 'AndTracking溯源平台',
                    url: '/andTracking',
                    img: home4PNG,
                    describe1: '产品生命周期全流程追溯',
                    describe2: '构建穿透式的质量管理体系'
                }
            ],
            latestNewsData: [
                { name: '成长之路', to: '/news/1', headerClassName: 'latestNewsItemHeader1', newsDataKey: 1 },
                { name: '行业助力', to: '/news/2', headerClassName: 'latestNewsItemHeader2', newsDataKey: 2 },
                { name: '技术空间', to: '/news/3', headerClassName: 'latestNewsItemHeader3', newsDataKey: 3 }
            ],
            newsData: [],
            coreCompetenceData: [
                { name: '区块链基础平台', img: onePNG, describe: '安全、高效、灵活的区块链基础设施，支持权限管理、密码学、账本、P2P网络、交易与共识等核心模块。' },
                { name: '行业解决方案', img: twoPNG, describe: '丰富的区块链产品与行业应用积累，帮助客户快速构建跨机构业务协同体系，打造智能化、自动化的可信业务生态。' },
                { name: '专业咨询服务', img: threePNG, describe: '提供专业的区块链业务咨询与开发运维支持，包括案例分析、业务搭建、运营规划和技术培训等。' }
            ],
            productData: [
                {
                    name: '区块链底层平台',
                    url: '/andChain',
                    fullName: 'AndChain区块链底层平台',
                    case: '合作案例：首都版权协会',
                    describe: '采用成熟、可靠、高效的区块链底层技术，充分保障权限管理、隐私保护、信息安全、高效计算等需求，高扩展性的设计充分满足灵活的架构升级需求，提供丰富的API接口支持与外部系统的对接。基于AndChain，可以实现区块链业务平台的快速开发，高效完成相关业务需求的落地。'
                },
                {
                    name: '数字物流管理',
                    url: '/andLogistics',
                    fullName: 'AndLogistics物流管理平台',
                    case: '合作案例：太平洋保险、融贸通、鑫运达',
                    describe: '利用区块链不可篡改、全程留痕的技术特征建立数据存证体系，并结合第三方强数据交叉验证帮助物流全周期业务数据上链，解决物流货运信息不实、不透明甚至造假问题。联盟链连接保险、金融、税务、监管等机构，让真实业务数据产生可信价值，助力物流方获得普惠金融和保险支持。'
                },
                {
                    name: '供应链金融',
                    url: '/andFinance',
                    fullName: 'AndFinance供应链金融平台',
                    case: '合作案例：新码供应链',
                    describe: '利用应收账款电子凭证的拆分、流转、融资，将供应链核心企业的信任传递到上游多级供应商，借助银行、保理等金融机构打造供应链金融产业生态闭环，解决中小微企业融资难的问题，拓展金融机构业务渠道，加强核心企业供应链管理，促进各业务方互利合作，推动生态健康发展。'
                },
                {
                    name: '商品溯源',
                    url: '/andTracking',
                    fullName: 'AndTracking溯源平台',
                    case: '合作案例：赛唐文化',
                    describe: '区块链溯源平台对商品生产、加工、批发、零售的全供应链流程进行记录和存证，实现对商品供应链上每个流转环节的追溯查询，不仅可以准确获取商品的来源信息，还可以对商品的销售分布展开分析。利用区块链技术分布式存储、不可篡改的特性，实现来源可查、去向可追、责任可究、风险可控的穿透式质量管理体系，提升企业的质量管理能力，建立良好的供应链生态体系，充分满足监管合规需求。'
                },
                {
                    name: '数字版权',
                    url: '/blockchainCopyright',
                    fullName: '区块链数字版权平台',
                    case: '合作案例：首都版权协会',
                    describe: '区块链技术能够制造一个不可更改的时间戳，将作者、创作时间和内容生成一个独一无二的“哈希”摘要，再将哈希摘要记录到区块链当中。基于区块链数字版权平台，数字作品⽣产者能够将数字版权登录上链、挂牌和分发授权；产权管理的平台能够对版权进⾏审批、管理和监控；普通⽤户可以自由购买卖出版权的权益。'
                },
                {
                    name: '网络赋强公证',
                    url: '/blockchainNetwork',
                    fullName: '网络赋强公证平台',
                    case: '合作案例：易证科技',
                    describe: '区块链平台上游对接银行等金融机构、下游对接法院执行部门的公证办证系统，对电子借贷合同进行在线公证，并赋予强制执行效力，一方不履行合同时，由公证机构出具执行证书，批量推送至法院立案执行。从债权人向公证处申请出具执行证书之日起，经过公证处催告，出具执行证书，再到批量推送至法院立案执行，执行失败债务人进入限高人员、失信人员名单，用时可以缩小至两个月以内，且采用全网操作的模式。'
                }
            ],
            productKey: 0,
            mobileProductKey: '',
            showNewsButton: false
        };
        this.setInter = null;
    }

    prev = () => {
        this.refs.carousel.prev();
    };
    next = () => {
        this.refs.carousel.next();
    };

    componentDidMount() {
        this.getNews();
        let headerDom = document.getElementsByClassName('commonHeader')[0];
        if (headerDom) {
            headerDom.style.position = 'fixed';
            headerDom.style.zIndex = 20;
        }
        window.onscroll = () => {
            try {
                let headerDom = document.getElementsByClassName('commonHeader')[0];
                if (headerDom) {
                    let homesSubHeaderDom = document.getElementById('homeSubHeader');
                    if (homesSubHeaderDom) {
                        let top = homesSubHeaderDom.getBoundingClientRect().top;
                        if (top > -10) {
                            headerDom.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
                        } else {
                            headerDom.style.backgroundColor = '#141414';
                        }
                    }
                }

                // 滚动控制右侧悬浮框
                this.setTime && clearTimeout(this.setTime)
                let element = document.querySelector('.scroll-top')
                element.style.left = -130 + 'px'
                this.setTime = setTimeout(() => {
                    document.querySelector('.scroll-top').style.left = 30 + 'px'
                }, 700);
            } catch (err) {
                console.error(err);
            }
        }
    }

    componentWillUnmount() {
        let headerDom = document.getElementsByClassName('commonHeader')[0];
        if (headerDom) {
            headerDom.style.position = 'relative';
            headerDom.style.zIndex = 2;
            headerDom.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
        }
        window.onscroll = null;
    }

    // 返回顶部
    scrollTop = () => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
        });
    }

    getNews = () => {
        getNews({}).then((res) => {
            this.setState({ newsData: res.data });
            setTimeout(() => {
                this.setState({ showNewsButton: true });
            }, 10);
        });
    };

    jump = (id) => {
        this.props.history.push({ pathname: '/newsDetails/' + id });
    };

    toOtherPage = (pathname, name) => {
        if (name === '客加，您的客户增长专家！') {
            window.open('https://cusplus.com/#/');
        } else {
            this.props.history.push({ pathname: pathname });
        }
    };

    // 渲染新闻
    renderLatestNewsRoot = () => {
        let { newsData, latestNewsData } = this.state;
        return latestNewsData.map(item => {
            return <div className={'latestNewsItem'} key={item.name}>
                <div className={item.headerClassName}>
                    <div className={'latestNewsItemHeaderName'}>{item.name}</div>
                    <Link to={item.to}>
                        <div className={'latestNewsItemHeaderButton'}>查看更多</div>
                    </Link>
                </div>
                <div className={'newsCardRoot'}>
                    {newsData && newsData[item.newsDataKey] && newsData[item.newsDataKey].map((item) => {
                        return (
                            <div className={'newsCard'} key={item.newsid}>
                                <div
                                    className={'newsCardTitle'}
                                    title={item.title}
                                    onClick={() => this.jump(item.newsid)}
                                >
                                    {item.title && overtopOmit(item.title, 35)}
                                </div>
                                <div className={'newsCardTime'}>{item.pubdate}</div>
                            </div>
                        );
                    })}
                </div>
            </div>;
        });
    };

    hoverImg = (imgId, type) => {
        let img = document.getElementById(imgId);
        if (imgId === 'rightImg') {
            if (type === 'over') {
                img.src = rightPath;
            } else {
                img.src = rightPNG;
            }
        } else {
            if (type === 'over') {
                img.src = leftPath;
            } else {
                img.src = leftPNG;
            }
        }
    }

    render() {
        let { isMobile, carouselData, coreCompetenceData, productKey, productData, showNewsButton, mobileProductKey } = this.state;
        let productDetail = productData[productKey];
        return (
            <div className={`home ${isMobile ? 'mobileHome' : ''}`} style={{ minWidth: isMobile ? '' : '1200px' }}>
                {/* 头部*/}
                <div className={'homeSubHeader'} id={'homeSubHeader'}>
                    <div className="homeCarouselRoot">
                        <img className="left" id="leftImg" src={leftPNG} alt={''}
                            onMouseOver={() => this.hoverImg("leftImg", 'over')}
                            onMouseOut={() => this.hoverImg("leftImg", 'out')}
                            onClick={this.prev} />
                        <img className="right" id="rightImg" src={rightPNG} alt={''}
                            onMouseOver={() => this.hoverImg("rightImg", 'over')}
                            onMouseOut={() => this.hoverImg("rightImg", 'out')}
                            onClick={this.next} />
                        {/* autoplay */}
                        <Carousel ref="carousel" 
                        // autoplay
                        autoplaySpeed={3000}>
                            {
                                carouselData.map(item => {
                                    return <div key={item.name}>
                                        <div
                                            className={'carouselItem'}
                                            style={{ backgroundImage: `url(${item.img})` }}
                                        >
                                            <div className={`carouselItemContent ${item.name === '客加，您的客户增长专家！' ? 'centerItemContent' : ''}`}>
                                                <div>{item.name}</div>
                                                <div>{item.describe1}</div>
                                                <div>{item.describe2}</div>
                                                <Button
                                                    onClick={this.toOtherPage.bind(this, item.url, item.name)}
                                                    // style={{ height: item.name === '客加，您的客户增长专家！' ? '0.8rem' : '.41rem' }}
                                                    className={item.name === '客加，您的客户增长专家！' ? 'clickBtn' : ''}
                                                >{item.name === '客加，您的客户增长专家！' ? '点击试用' : '了解更多'}</Button>
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                        </Carousel>
                    </div>
                </div>
                {/* 最新资讯*/}
                <div className={'homeDivRoot'}>
                    <div className={'homeDiv'}>
                        <div className={'homeDivTitle'}>最新资讯</div>
                        <div className={'latestNewsRoot'}>
                            {
                                isMobile ?
                                    <AntdMobileCarousel
                                        dots={false}
                                        frameOverflow="visible"
                                        cellSpacing={20}
                                        slideWidth={0.8}
                                    >
                                        {this.renderLatestNewsRoot()}
                                    </AntdMobileCarousel> :
                                    this.renderLatestNewsRoot()
                            }
                        </div>
                        {showNewsButton ? <Link to="/news">
                            <div className="homeNewsButton">查看全部新闻</div>
                        </Link> : null}
                    </div>
                </div>
                {/* 核心能力*/}
                <div className={'homeDivRoot'} style={{ backgroundColor: '#F8F9FC' }}>
                    <div className={'homeDiv'}>
                        <div className={'homeDivTitle'}>核心能力</div>
                        <div className={'coreCompetenceRoot'}>
                            {
                                coreCompetenceData.map(item => {
                                    return <div key={item.name} className={'coreCompetence'}>
                                        <div className={'coreCompetenceImgRoot'}>
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className={'coreCompetenceBottom'}>
                                            <div className={'coreCompetenceName'}>{item.name}</div>
                                            <div className={'coreCompetenceText'}>{item.describe}</div>
                                        </div>
                                    </div>;
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* 产品与解决方案*/}
                <div className={'homeDivRoot'}>
                    <div className={'homeDiv'}>
                        <div className={'homeDivTitle'}>产品与解决方案</div>
                        {isMobile ? <div className={'mobileHomeProductRoot'}>
                            {
                                productData.map((item, index) => {
                                    return <div key={item.name} className={'mobileHomeProduct'}>
                                        <div
                                            onClick={() => { this.setState({ mobileProductKey: mobileProductKey === index ? '' : index }); }}
                                            className={`mobileProductItem ${mobileProductKey === index ? 'mobileProductItemActive' : ''}`}>
                                            <div className={'mobileProductItemActiveBg1'} />
                                            <div className={'mobileProductItemActiveBg2'} />
                                            <span>{item.name}</span>
                                            {mobileProductKey === index ? <MinusOutlined /> : <PlusOutlined />}
                                        </div>
                                        <div
                                            className={'mobileHomeProductItemBottom'}
                                            style={{
                                                height: mobileProductKey === index ? 'auto' : 0,
                                                padding: mobileProductKey === index ? '' : '0'
                                            }}
                                        >
                                            <div className={'mobileProductName'}>
                                                <span>{item?.fullName}</span>
                                            </div>
                                            <div className={'mobileProductDescribe'}>{item?.describe}</div>
                                            {
                                                item?.fullName === '网络赋强公证平台' ? ''
                                                    :
                                                    <div className={'productRightCase'}>{item?.case}</div>
                                            }
                                            {/*<div className={'mobileProductCase'}>{item?.case}</div>*/}
                                            <div
                                                onClick={this.toOtherPage.bind(this, item?.url)}
                                                className={'mobileProductButton'}>查看更多
                                            </div>
                                            <img className={'mobileProductIcon'} src={homeProductRightIconPNG} alt="" />
                                        </div>
                                    </div>;
                                })
                            }
                        </div> :
                            <div className={'homeProductRoot'}>
                                <div className={'productLeft'}>
                                    {
                                        productData.map((item, index) => {
                                            return <div
                                                onClick={() => { this.setState({ productKey: index }); }}
                                                key={item.name}
                                                className={`productItem ${productKey === index ? 'productItemActive' : ''}`}>
                                                <div className={'productItemActiveBg1'} />
                                                <div className={'productItemActiveBg2'} />
                                                <span>{item.name}</span>
                                            </div>;
                                        })
                                    }
                                </div>
                                <div className={'productRight'}>
                                    <div className={'productRightName'}>
                                        <span>{productDetail?.fullName}</span>
                                        <img src={homeProductIconPNG} alt="" />
                                    </div>
                                    <div className={'productRightDescribe'}>{productDetail?.describe}</div>
                                    {
                                        productDetail?.fullName === '网络赋强公证平台' ? ''
                                            :
                                            <div className={'productRightCase'}>{productDetail?.case}</div>
                                    }
                                    <div
                                        onClick={this.toOtherPage.bind(this, productDetail?.url)}
                                        className={'productRightButton'}>查看更多
                                    </div>
                                    <img className={'productRightIcon'} src={homeProductRightIconPNG} alt="" />
                                </div>
                            </div>}
                    </div>
                </div>
                {/* 合作伙伴*/}
                <div className={'homeDivRoot'} style={{ backgroundColor: '#F8F9FC' }}>
                    <div className={'homeDiv'}>
                        <div className={'homeDivTitle'}>合作伙伴</div>
                        <div className={'partnersRoot'}>
                            <img alt={''} src={require('../../assets/images/partners/img1.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img2.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img3.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img4.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img5.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img6.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img7.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img8.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img9.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img10.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img11.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img12.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img14.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img13.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img15.png')} />
                            <img alt={''} src={require('../../assets/images/partners/img16.png')} />
                        </div>
                    </div>
                </div>
                {/* 联系我们*/}
                <div className={'homeBottomRoot'}>
                    <div className={'homeBottom'}>
                        <div className={'homeBottomLeft'}>
                            <span>企业级区块链开发服务，无障碍对接上层业务场景</span>
                            <span>联系我们 即可启程</span>
                        </div>
                        <Button onClick={this.toOtherPage.bind(this, '/programAns/home')}>立即咨询</Button>
                    </div>
                </div>
                {/* <CommonRightHelp scrollTop={this.scrollTop}></CommonRightHelp> */}
            </div>
        );
    }
}
