import React, { Component } from 'react';
import { isMobile } from '../../utils/util';
import { Popover, Button } from 'antd';
import "./commonFixedButton.scss";
import "./mobileCommonFixedButton.scss";
import qrCodePNG from '../../assets/images/common/qrCode.png';
import Close from '../../assets/images/common/Close.png';
import headphones from '../../assets/images/common/headphones.png';

import {
    PhoneOutlined,
    WechatOutlined,
    MobileOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

class CommonFixedButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            clicked: true,
            hoverContent: ''
        };
    }

    componentDidMount() {
        this.getCommonContent();
    }

    getCommonContent = (visible) => {
        let hoverContent = '';
        hoverContent = <div className={'helpContent'}>
            {/* <CloseCircleOutlined className={'closeCircleOutlined'} onClick={() => this.setState({
                clicked: false
            })}/> */}
            <img src={Close} alt="" className={'closeCircleOutlined'} onClick={() => this.setState({
                clicked: false
            })} />
            <div className={'helpTitle'}>有什么可以帮您么?</div>
            <div className={'helpType'}>
                <div className={'cursorPointer'}
                    onClick={() => this.helpClick('telephone')}
                ><MobileOutlined className={'inconStyle'} />电话咨询
                </div>
                <div className={'cursorPointer'}
                    onClick={() => this.helpClick('onLine')}
                ><WechatOutlined className={'inconStyle'} />在线咨询
                </div>
            </div>
        </div>;

        this.setState({
            hoverContent,
            clicked: true
        })
    }

    helpClick = (type) => {
        let hoverContent = '';
        if (type === 'telephone') {
            hoverContent = <div className={'helpContent'}>
                <img src={Close} alt="" className={'closeCircleOutlined'} onClick={() => this.setState({
                    clicked: false
                })} />
                <div className={'tips'}>欢迎拨打电话</div>
                <div className={'tips telephone'}><span className={'telephoneNumber'}>025-83370906</span>进行询问</div>
            </div>
        } else {
            hoverContent = <div className={'helpContent onLineContent'}>
                <img src={Close} alt="" className={'closeCircleOutlined'} onClick={() => this.setState({
                    clicked: false
                })} />
                <img src={qrCodePNG} />
                <div className={'tips'}>微信扫描二维码，立即在线询问</div>
            </div>
        }
        this.setState({
            hoverContent
        })
    }

    render() {
        let { isMobile, hoverContent } = this.state;

        return (
            <div className={`commonFixedButton ${isMobile ? 'mobileCommonFixedButton' : ''}`}>
                <Popover
                    content={hoverContent}
                    placement="leftTop"
                    trigger="click"
                    onVisibleChange={this.getCommonContent}
                    visible={this.state.clicked}
                    getPopupContainer={() => document.getElementById('helpButton')}
                >

                    <div className={'helpButton'} id={'helpButton'}>
                        {/* <PhoneOutlined /> */}
                        <img src={headphones} alt="" className={'helpButtonEar'}/>
                    </div>
                </Popover>
            </div>

        );
    }
}

export default CommonFixedButton;