/**
 * Created by zal on 2020/11/2
 * 解决方案-业务流程
 * @param  data
 * { name: String, img: Image }
 */
import React from 'react';
import './commonDivRoot.scss';

function CommonOperationFlow ({ data }) {
    return (
        <div className={'commonDivRoot'} style={{ backgroundColor: '#F8F9FC' }}>
            <div className={'commonDiv'}>
                <div className={'commonTitle'}>{data?.name || '业务流程'}</div>
                <div className={'operationFlowImgRoot'}>
                    <img src={data?.img} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default CommonOperationFlow;
