import React, { Component } from "react";
import "./newsDetails.scss";
import './mobileNewsDetails.scss';
import { Layout, Spin } from "antd";
import { getNewsDetails } from "../../services/news";
import { isMobile, overtopOmit } from '../../utils/util';

const { Content } = Layout;
class NewsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            title: "",
            source: "",
            time: "",
            content: "",
            loading: false,
        };
    }

    componentDidMount = () => {
        let id = this.props.match.params.id;
        this.lookNewsDetails(id);
    };

    lookNewsDetails = (id) => {
        this.setState({
            loading: true,
        });
        getNewsDetails({ newsid: id }).then((res) => {
            this.setState(
                {
                    title: res.data.title,
                    source: res.data.source,
                    time: res.data.pubdate,
                    content: res.data.content,
                    loading: false
                }
            );
        });
    };

    render() {
        let { title, source, time, content, loading, isMobile } = this.state;
        return (
            <Layout className={`newsDetails ${isMobile ? 'mobileNewsDetails' : ''}`}>
                {/* 头部*/}
                <div className={'newsDetailsSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'compassProductName'}>新闻中心</div>
                        <div className={'compassProductDescribe'}>公司最新动态发布平台</div>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <Content
                        className="site-layout box-content"
                        style={{ margin: "30px auto" }}
                    >
                        <section>
                            <div style={{ textAlign: "center", marginBottom: '0.2rem' }}>
                                <div className="news-title">{title}</div>
                                <div className="news-little-title">
                                    <span className="news-time">发布时间：{time}</span>
                                      &emsp;&emsp;&emsp;
                                      <span className="news-source">来源：{source}</span>
                                </div>
                            </div>

                            <div
                                className="news-content"
                                dangerouslySetInnerHTML={{ __html: content }}
                            ></div>
                        </section>
                    </Content>
                </Spin>
                {/* <Bottom /> */}
            </Layout>
        );
    }
}

export default NewsDetails;
