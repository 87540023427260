/**
 * Created by zal on 2020/11/2
 * 公共头部
 */
import React, { Component } from 'react';
import './commonRightHelp.scss';
// import './mobileHeader.scss';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { isMobile } from '../../utils/util';
import counselor from '../../assets/images/home/counselor.png';
import phone from '../../assets/images/home/phone.png';
import arrow from '../../assets/images/home/arrow.png';

const { SubMenu } = Menu;

export default class CommonRightHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    toCustom() {
        window.open('https://cusplus.com/#/index');
    }

    render() {
        return (
            isMobile() ?
                ''
                :
                <div className="scroll-top">
                    <div className="scroll-top-box">
                        <img src={counselor} alt="" />
                        <h6>您好！<br />我是您的<span>客加</span>专属运营顾问<br />1对1为您答疑解惑</h6>
                        <h5>私域运营大礼包
                            <div className="clickBtn" onClick={this.toCustom}>点击领取</div>
                        </h5>
                        <p className="scroll-phone-info">
                            <img
                                src={phone}
                                alt=""
                                style={{ marginRight: '5px' }}
                            />
                            增长热线
                        </p>
                        <p className="scroll-phone">186-5536-7961</p>
                    </div>
                    <div className="scroll-icon" onClick={this.props.scrollTop}>
                        <img
                            src={arrow}
                            alt=""
                        />
                    </div>
                </div>
        );
    }
}

