/**
 * Created by zal on 2020/10/30
 * 应用领域
 */
import React, { Component } from 'react';
import './applicationScenarios.scss';
import './mobileApplicationScenarios.scss';
import 'antd-mobile/lib/carousel/style/css';
import as1PNG from '../../../assets/images/compass/as1.png';
import as2PNG from '../../../assets/images/compass/as2.png';
import as3PNG from '../../../assets/images/compass/as3.png';
import as4PNG from '../../../assets/images/compass/as4.png';
import asBg1PNG from '../../../assets/images/compass/asBg1.png';
import asBg2PNG from '../../../assets/images/compass/asBg2.png';
import asBg3PNG from '../../../assets/images/compass/asBg3.png';
import asBg4PNG from '../../../assets/images/compass/asBg4.png';
import asItemNamePNG from '../../../assets/images/compass/asItemName.png';
import * as antdMobile from 'antd-mobile';
import { isMobile } from '../../../utils/util';

const AntdMobileCarousel = antdMobile.Carousel;

class ApplicationScenarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            data: [
                {
                    name: '金融',
                    subName: '安全与风控',
                    img: as1PNG,
                    bgImg: asBg1PNG,
                    describe: '通过资金流向分析、用户行为分析和社交网络分析等方法对目标行为模式进行识别和监测，开展风险行为匹配和风险传导分析工作，可以广泛应用于银行、券商、监管机构的反欺诈、反洗钱、KYC/CDD调查流程当中。'
                },
                {
                    name: '公安',
                    subName: '案件调查/治安管理',
                    img: as2PNG,
                    bgImg: asBg2PNG,
                    describe: '基于话单分析、飞机同乘人分析、交易转帐分析和活动轨迹分析等手段开展案件调查工作，找出犯罪嫌疑人的关联对象，识别隐藏的犯罪团伙。基于沉淀的犯罪分子网络关联图数据构建智能治安管理系统，监控潜在的治安风险。'
                },
                {
                    name: '电子商务',
                    subName: '个性推荐',
                    img: as3PNG,
                    bgImg: asBg3PNG,
                    describe: '从数据计算的过程中研究个体及社群的购物习惯并以此进行个性推荐。一个典型的社群关系推荐链条：购物者 → 购买的产品 → 购买该产品的其他购物者 → 社群统计特征 → 属于该特征的其他购物者 → 其他购物者购买的产品。'
                },
                {
                    name: '生物医药',
                    subName: '药物研发/病毒传播模式研究',
                    img: as4PNG,
                    bgImg: asBg4PNG,
                    describe: '生物医药领域存在大量的相关关系，例如基因序列和蛋白质结构的关系等。通过构建数据协作和分析平台，能够助力药物研发和药物作用机理的研究。此外，图分析也天然地适用于分析病毒（疫情）传播的模式及其造成的影响。'
                }
            ]
        };
    }

    renderApplicationScenariosItem = () => {
        let { data } = this.state;
        return data.map(item => {
            return <div
                style={{ backgroundImage: `url(${item.bgImg})` }}
                className={'applicationScenariosItem'} key={item.name}
            >
                <div className={'applicationScenariosItemImg'}>
                    <img src={item.img} alt=""/>
                </div>
                <div className={'itemDescribeRoot'}>
                    <div className={'itemDescribeTitle'}>{item.subName}</div>
                    <div className={'itemDescribeText'}>{item.describe}</div>
                </div>
                <div className={'applicationScenariosItemName'}>
                    <img src={asItemNamePNG} alt=""/>
                    <div className={'applicationScenariosItemText'}>{item.name}</div>
                    <img className={'asItemNameImg2'} src={asItemNamePNG} alt=""/>
                </div>
            </div>;
        });
    };

    render() {
        let { isMobile } = this.state;
        return (
            <div className={`applicationScenariosRoot ${isMobile ? 'mobileApplicationScenarios' : ''}`}>
                <div className={'applicationScenarios'}>
                    <div className={'applicationScenariosName'}>应用领域</div>
                    <div className={'applicationScenariosItemRoot'}>
                        {
                            isMobile ?
                                <AntdMobileCarousel
                                    dots={true}
                                    frameOverflow="visible"
                                    cellSpacing={20}
                                    slideWidth={0.8}
                                >
                                    {this.renderApplicationScenariosItem()}
                                </AntdMobileCarousel> :
                                this.renderApplicationScenariosItem()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplicationScenarios;
