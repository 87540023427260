import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from '../views/home/Home';
import CommonHeader from '../components/commonHeader/CommonHeader';
import Bottom from "../components/bottom/Bottom";
import AndChain from '../views/andChain/AndChain';
import AndLogistics from '../views/andLogistics/AndLogistics';
import AndFinance from '../views/andFinance/AndFinance';
import AndTracking from '../views/andTracking/AndTracking';
import Anstar from '../views/anstar/Anstar';
import Compass from '../views/compass/Compass';
import ProgramAns from '../views/programAns/ProgramAns';

import BlockchainCertificate from '../views/blockchainCertificate/BlockchainCertificate';    // 区块链存证
import BlockchainCopyright from '../views/blockchainCopyright/BlockchainCopyright';   // 区块链数字版权
import BlockchainLicense from '../views/blockchainLicense/BlockchainLicense';  // 区块链电子证照
import BlockchainNetwork from '../views/blockchainNetwork/BlockchainNetwork';  // 区块链网络赋强公证

import News from '../views/news/News';
import About from "../views/about/About";
import Joinus from "../views/joinus/Joinus.jsx";

import ScrollToTop from '../components/scrollToTop';
import NewsDetails from "../views/newsDetails/NewsDetails";
import CommonFixedButton from "../components/commonFixedButton/CommonFixedButton";

function BasicExample() {
        return (
            <Router>
                <div>
                    <ScrollToTop>
                        <CommonHeader />
                        <CommonFixedButton />
                        <Route exact path="/" component={Home} />
                        {/* 核心产品 */}
                        <Route path="/andChain" component={AndChain} />{/* AndChain */}
                        <Route path="/andLogistics" component={AndLogistics} />{/* AndLogistics */}
                        <Route path="/andFinance" component={AndFinance} />
                        <Route path="/andTracking" component={AndTracking} />
                        <Route path="/anstar" component={Anstar} />
                        <Route path="/compass" component={Compass} />{/* compass */}

                        <Route path="/blockchainCertificate" component={BlockchainCertificate} />
                        <Route path="/blockchainCopyright" component={BlockchainCopyright} />
                        <Route path="/blockchainLicense" component={BlockchainLicense} />
                        <Route path="/blockchainNetwork" component={BlockchainNetwork} />

                        <Switch>
                            <Route exact={true} path="/news" component={News} />
                            <Route path="/news/:type" component={News} />
                        </Switch>
                        {/* 新闻 */}
                        <Switch>
                            <Route path="/about" component={About} exact />{/* 关于安链 */}
                            <Route path="/about/:type" component={About} />
                        </Switch>
                        <Route path="/joinus" component={Joinus} />{/* 加入我们 */}
                        <Route path="/newsDetails/:id" component={NewsDetails} />{/*新闻详情 */}
                        <Route path="/programAns/:type" component={ProgramAns} />{/*业务咨询 */}
                        <Bottom />
                    </ScrollToTop>
                </div>
            </Router>
        );
}

export default BasicExample;
