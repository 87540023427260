import React, {Component} from "react";
import "./andLogistics.scss";
import "./mobileAndLogistics.scss";
import {Layout, Menu, Button, Row, Col} from "antd";
import CommonBottomBox from "../../components/commonBottomBox/CommonBottomBox";
import CommonValueBox from "../../components/commonValueBox/CommonValueBox";
import program from "../../assets/images/andLogistics/program.png";
import programMobile from "../../assets/images/andLogistics/programMobile.png";

import rightImg from "../../assets/images/andLogistics/rightImg.png";
import {isMobile} from "../../utils/util";
import CommonLeftDrawer from "../../components/CommonLeftDrawer/CommonLeftDrawer";

const {Content} = Layout;

const DataFastService = [
    {
        name: '区块链浏览器',
        content: '区块链节点监控，上链信息实时展示'
    },
    {
        name: '运单详情',
        content: '链上货单运单，车辆信息追溯可查'
    },
    {
        name: '投保申请',
        content: '在线申请保险，智能合约自动投保'
    },
    {
        name: '数据隐私',
        content: '数字身份管理，链上隐私数据授权'
    }
];

const DataValue = [
    {
        name: '金融服务方',
        img: require('../../assets/images/andLogistics/1.png'),
        content: '提升对物流体系中融资主体的精准评估能力与风控能力。数据的高度可信与高效传递将推动银行等相关金融服务方制定更有市场竞争力的金融策略和产品，提升市场影响力并带来实际收益。'
    },
    {
        name: '保险公司',
        img: require('../../assets/images/andLogistics/2.png'),
        content: '推动物流方运营数据上链，减少货运保险申报过程中漏报、瞒报、误报的现象。由于货运信息事前上链，单证齐全，保险理赔定损和核赔效率将有效提高，为网络货运保险的推广奠定了基础，并为信用保证类保险风控服务提供数据支持。'
    },
    {
        name: '物流从业者',
        img: require('../../assets/images/andLogistics/3.png'),
        content: '根据可他证的真实业务数据形成数据资产，提升信用价值，从而更便利地获取保险、融资等金融相关服务。同时区块链技术的应用也将优化资源利用率，压缩中间环节，提升行业整体效率。'
    }
];

const partnerImages = [
    require('../../assets/images/partnerBigImgs/RMT.png'),
    require('../../assets/images/partnerBigImgs/TPYBX.png')
];

class AndLogistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    toOtherPage = (pathname) => {
        this.props.history.push({pathname: pathname});
    };

    render() {
        let {isMobile} = this.state;
        return (
            <div
                className={`andLogistics ${isMobile ? 'mobileAndLogistics' : ''}`}
                style={{minWidth: isMobile ? '' : '1200px'}}>
                {/* 头部*/}
                <div className={'andLogisticsSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        {
                           isMobile ?
                                <div className={'andLogisticsProductName'}>AndLogistics<br />物流管理平台</div>
                                :
                                <div className={'andLogisticsProductName'}>AndLogistics物流管理平台</div>
                        }
                        <div className={'andLogisticsDescribe'}>构建多方协作、信任传递的物流管理新模式</div>
                        {
                            isMobile ? '' :
                                <Button style={{height: '.41rem'}} type="primary" onClick={
                                    () => window.open('http://huiyun.andlinks.com')
                                }>立即体验</Button>
                        }
                        <Button className={'consultButton'} style={{height: '.41rem'}}
                                onClick={this.toOtherPage.bind(this, '/programAns/andLogistics')}>业务咨询</Button>
                    </div>
                </div>
                <Content className="site-layout box-content">
                    <div className="firstSection">
                        <div className="headerTitle firstTitle">
                            连接物流、保险、金融，助力普惠金融服务
                        </div>
                        <div className="subTitle">
                            利用区块链不可篡改、全程留痕的技术特征并结合第三方强数据交叉验证帮助物流全周期业务数据上链，解决物流货运信息不实、不透明甚至造假问题。联盟链连接
                            保险、金融、税务、监管等机构，让真实业务数据产生可信价值，助力物流车队获得普惠金融和保险支持。
                        </div>
                        <img src={isMobile ? programMobile : program} alt="" className="programImg"/>
                    </div>
                    <div className="firstSection secSection">
                        <div className="headerTitle">
                            完善的功能支持
                        </div>
                        <CommonLeftDrawer DataFastService={DataFastService}
                                          rightImg={rightImg} />
                    </div>
                    <div className="firstSection ">
                        <div className="headerTitle">
                            促进业务各方价值提升
                        </div>
                        <CommonValueBox DataValue={DataValue} titleHeight={"460px"}/>
                    </div>
                </Content>
                <CommonBottomBox partnerTitle={'构建多方协作、信任传递的物流管理新模式'}
                                 partnerImages={partnerImages}
                />
            </div>
        );
    }
}

export default AndLogistics;
