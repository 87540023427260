import React, { Component } from "react";
import "./anstar.scss";
import "./mobileAnstar.scss";
import { Layout, Button } from "antd";
import CommonBottomBox from "../../components/commonBottomBox/CommonBottomBox";
import CommonAnstarSafety from "../../components/commonAnstarSafety/CommonAnstarSafety";
import CommonBehavior from "../../components/commonBehavior/CommonBehavior";
import ProgramAns from "../../views/programAns/ProgramAns";

import model from '../../assets/images/anstar/model.png';
import monitor from '../../assets/images/anstar/monitor.png';
import modelMobile from '../../assets/images/anstar/modelMobile.png';
import monitorMobile from '../../assets/images/anstar/monitorMobile.png';

import dataSource from '../../assets/images/anstar/dataSource.png';
import overView from '../../assets/images/anstar/overView.png';
import behavior from '../../assets/images/anstar/behavior.png';
import term from '../../assets/images/anstar/term.png';
import behaviorMonitor from '../../assets/images/anstar/behaviorMonitor.png';

import dataSourceMobile from '../../assets/images/anstar/11.png';
import overViewMobile from '../../assets/images/anstar/22.png';
import behaviorMobile from '../../assets/images/anstar/33.png';
import termMobile from '../../assets/images/anstar/44.png';
import behaviorMonitorMobile from '../../assets/images/anstar/55.png';

import { isMobile } from '../../utils/util';

const { Content } = Layout;

const SAFETY_ARR = [
    {
        name: '大数据汇集',
        headerTitle: ['大数据汇集', '快速打通数据孤岛'],
        img: isMobile() ? dataSourceMobile : dataSource,
        subTitleArr: [
            {
                name: '行为记录系统对接',
                title: '数据来源涵盖各种类型、相互独立的行为记录系统，包括但不限于钉盘数据、解密数据、云盘数据、云打印数据、设备数据、考勤数据、邮件数据、上网数据和R盘数据等。'
            },
            {
                name: '海量数据处理能力',
                title: '数据清洗解析能力达亿级，数据处理高度自动化的同时保障数据完整性，整合各类场景事故处理逻辑，数据容灾能力强。'
            }]
    },
    {
        name: '全局风险概览',
        headerTitle: ['全局风险概览', '把控整体安全态势'],
        img: isMobile() ? overViewMobile : overView,
        subTitleArr: [
            {
                name: '安全概览',
                title: '快速把控重点关注的企业行为安全模块，包括设备管控、文档流向、上网行为、信息资产等，提升监管和决策效率。'
            },
            {
                name: '行为主体分析',
                title: '汇集行为主体的基础信息和行为信息，进行360°无死角探索，风险操作重点标识，部门信息分类总结。'
            },
            {
                name: '行为主题分析',
                title: '以设备、邮件、文档、上网为主题开展分析，重点关注设备管控范围与力度、邮件外传风险、文档流程、工作与兴趣倾向等。'
            },
            {
                name: '安全评分',
                title: '根据成熟的评价规则对企业各区域、各部门的行为安全态势进行评分，找出企业信息安全的薄弱环节。'
            }]
    },
    {
        name: '行为衔接与追溯',
        headerTitle: ['行为衔接与追溯', '关联关系探寻'],
        img: isMobile() ? behaviorMobile : behavior,
        subTitleArr: [
            {
                name: '行为衔接分析',
                title: '在数据交杂的情况下，隐藏信息难以发掘，尤其是行为衔接、文件流转等方面。例如监控邮件只能监控到邮件在邮箱中的流转，无法监控到通过网络途径上传之后的流转。将云盘系统、加密系统、邮件系统衔接到一起，实现全流程追溯，形成完整证据链。'
            },
            {
                name: '关联分析',
                title: '通过构建关联关系图谱发掘行为主体隐藏的前同事关系、校友关系、亲属关系等，开展风险传导分析和群体性风险行为预警。'
            }]
    },
    {
        name: '多端立体化支持',
        headerTitle: ['多端立体化支持', '架构灵活可扩展'],
        img: isMobile() ? behaviorMonitorMobile : behaviorMonitor,
        subTitleArr: [
            {
                name: '多端支持',
                title: '网页端、移动端全方位支持，充分满足不同场合工作协同需求。'
            },
            {
                name: '高拓展性',
                title: '从底层架构出发，支持各类服务器节点以及数据节点拓展和功能拓展。从页面自定义出发，支持页面布局、页面组件以及组件元素自定义，可快速进行页面拓展。'
            }
        ]
    },
    {
        name: '行为预测与预警',
        headerTitle: ['自定义模型指标', '行为预测与安全预警'],
        img: isMobile() ? termMobile : term,
        subTitleArr: [
            {
                name: '指标评分',
                title: '支持标签与评分体系规则自定义，根据企业的实际情况对员工行为进行量化评估。'
            },
            {
                name: '模型沉淀',
                title: '依托人工智能算法构建分析模型，通过企业的历史数据沉淀对员工行为特征进行归纳总结，例如员工离职、关键岗位特征等。'
            },
            {
                name: '安全预警',
                title: '当触发相关的行为模型时，快速预警风险行为，例如离职风险、文件泄密风险等。'
            }
        ]
    },
];

const partnerImages = [
    require('../../assets/images/partnerBigImgs/XDSW.png'),
    require('../../assets/images/partnerBigImgs/ZDTQ.png'),
    require('../../assets/images/partnerBigImgs/HANHUI.png')
];

const MANAGER_ARR = [
    {
        name: '上网行为监控',
        title: '记录员工上网时长、访问网站的类型，是否在工作时间浏览非相关网站等。'
    },
    {
        name: '文件防泄密',
        title: '全面监控钉盘数据、云盘数据、U盘数据、云打印数据、邮件数据等，杜绝文件外发与泄密风险。'
    },
    {
        name: '行为衔接分析',
        title: '追踪文件从下载、分享、外传到打印的完整流转过程，实现全流程追溯，形成完整的证据链。'
    },
    {
        name: '关联分析',
        title: '通过构建知识图谱发掘行为主体隐藏的前同事关系、校友关系、亲属关系等，开展风险传导分析和群体风险行为预警。'
    },
    {
        name: '全局风险概览',
        title: '根据成熟的评价规则对企业各区域、各部门的行为安全态势进行评分，找出企业信息安全的薄弱环节。'
    },
    {
        name: '指标与评分',
        title: '通过构建知识图谱发掘行为主体隐藏的前同事关系、校友关系、亲属关系等，开展风险传导分析和群体风险行为预警。'
    },
    {
        name: '安全预警',
        title: '当员工行为触发预置模型时，将对潜在风险进行预警，通过钉钉、邮件、站内消息多端推送。'
    },
    {
        name: '离职预测',
        title: '分析员工的行为特征，结合模型匹配和关联分析，预测员工可能的离职行为。'
    },
];

class Anstar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            selectBtn: '大数据汇集',
            chooseItem: SAFETY_ARR[0],
            chooseIndex: 0
        };
        this.timer = null;
    }

    componentDidMount() {
        if (!this.state.isMobile) {
            this.setTimer();
        }
    }

    // 定时器
    setTimer = () => {
        let { chooseIndex } = this.state;
        this.timer = setInterval(() => {
            if (chooseIndex === 2) {
                chooseIndex = 4;
                this.setState({
                    chooseItem: SAFETY_ARR[4],
                    chooseIndex
                })
            } else if (chooseIndex === 4) {
                chooseIndex = 3;
                this.setState({
                    chooseItem: SAFETY_ARR[3],
                    chooseIndex
                })
            } else if (chooseIndex === 3) {
                chooseIndex = 0;
                this.setState({
                    chooseItem: SAFETY_ARR[0],
                    chooseIndex
                })
            } else {
                chooseIndex++;
                this.setState({
                    chooseItem: SAFETY_ARR[chooseIndex],
                    chooseIndex: chooseIndex
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        if (this.timer != null) {
            clearInterval(this.timer);
        }
    }

    toOtherPage = (pathname) => {
        this.props.history.push({ pathname: pathname });
    };

    chooseBtn(item, index) {
        this.setState({
            selectBtn: item.name,
            chooseItem: item,
            chooseIndex: index
        }, () => {
            if (this.timer != null) {
                clearInterval(this.timer);
                this.setTimer();
            }
        })
    }

    // 手机端的上一个 下一个
    changeItem = (type) => {
        let { chooseIndex } = this.state;
        if (type === 'next') {
            if (chooseIndex === 2) {
                chooseIndex = 4;
                this.setState({
                    chooseItem: SAFETY_ARR[4],
                    chooseIndex
                })
            } else if (chooseIndex === 4) {
                chooseIndex = 3;
                this.setState({
                    chooseItem: SAFETY_ARR[3],
                    chooseIndex
                })
            } else if (chooseIndex === 3) {
                chooseIndex = 0;
                this.setState({
                    chooseItem: SAFETY_ARR[0],
                    chooseIndex
                })
            } else {
                chooseIndex++;
                this.setState({
                    chooseItem: SAFETY_ARR[chooseIndex],
                    chooseIndex: chooseIndex
                })
            }

        } else {
            if (chooseIndex === 0) {
                this.setState({
                    chooseItem: SAFETY_ARR[3],
                    chooseIndex: 3
                })
            } else if (chooseIndex === 3) {
                this.setState({
                    chooseItem: SAFETY_ARR[4],
                    chooseIndex: 4
                })
            } else if (chooseIndex === 4) {
                this.setState({
                    chooseItem: SAFETY_ARR[2],
                    chooseIndex: 2
                })
            } else {
                chooseIndex--;
                this.setState({
                    chooseItem: SAFETY_ARR[chooseIndex],
                    chooseIndex: chooseIndex
                })
            }
        }
    }

    render() {
        let { chooseItem, chooseIndex, isMobile } = this.state;
        return (
            <Layout
                className={`anstar ${isMobile ? 'mobileAnstar' : ''}`}
                style={{ minWidth: isMobile ? '' : '1200px' }}
            >
                {/* 头部*/}
                <div className={'anstarSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        {
                            isMobile ?
                                <div className={'compassProductName'}><span style={{ fontSize: '0.52rem' }}>Anstar</span><br />企业行为安全感知平台</div>
                                :
                                <div className={'compassProductName'}>Anstar企业行为安全感知平台</div>
                        }
                        <div className={'compassProductDescribe'}>10年经验大数据开发团队倾力打造，获得多家上市公司信赖</div>
                        <Button className={'consultButton'} style={{ height: '.41rem' }}
                            // onClick={this.toOtherPage.bind(this, '/programAns/anstar')}
                            onClick={() => {
                                let anchorElement = document.getElementById('forFreeAns');
                                if(anchorElement) {
                                    anchorElement.scrollIntoView(
                                        {behavior: 'smooth'}
                                    );
                                }
                                // window.scrollTo({ top: 4100, behavior: "smooth" })
                            }}
                        >免费试用</Button>
                    </div>
                </div>

                <Content className="site-layout anstarContent">
                    <div className="firstSection backgroundColor">
                        <div className="headerTitle">
                            {
                                isMobile ?
                                    <span>全方位管控员工行为<br />防范管理风险</span>
                                    : '全方位管控员工行为，防范管理风险'
                            }
                        </div>
                        <CommonBehavior hebaviorData={MANAGER_ARR} />
                    </div>

                    <div className="firstSection">
                        <div className="headerTitle">
                            行为安全管理闭环
                        </div>

                        <div className="borderContent">
                            <div className="safetyBox">
                                {
                                    SAFETY_ARR.map((item, index) => {
                                        return <div key={index}
                                            className={index === chooseIndex ? "safetyButton chooseBtn" : 'safetyButton'}
                                            onClick={() => this.chooseBtn(item, index)}
                                        >
                                            {item.name}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div onMouseMove={() => {
                            if (this.timer != null) {
                                clearInterval(this.timer);
                            }
                        }}
                            onMouseLeave={() => {
                                if (!this.state.isMobile) {
                                    this.setTimer();
                                }
                            }}
                        >
                            <CommonAnstarSafety chooseItem={chooseItem} changeItem={this.changeItem} />
                        </div>
                    </div>
                    <div className="firstSection backgroundColor lastHeight">
                        <div className="headerTitle">
                            员工关键操作全流程监控
                        </div>
                        <img src={isMobile ? monitorMobile : monitor} alt="" className="imgModel" />
                    </div>

                    <div className="firstSection">
                        <div className="headerTitle">
                            行为感知、汇集、建模与预警
                        </div>
                        <div className="subTitle">
                            基于全量日志数据（用户邮件发送、文件解密、文件下载、文档打印、电脑登录等日志），结合设定风险规则，利用数据挖掘算法，建立一套科学的行为评估模型和标签体系，全面客观地评估员工行为，降低其可能带来的管理风险。最终通过本系统实现企业信息安全的区域管控、设备管控、人员管控和信息资产管控。
                        </div>
                        <img src={isMobile ? modelMobile : model} alt="" className="imgModel" />
                    </div>

                </Content>
                <div className="bottomAnstarSafety">
                    <CommonBottomBox
                        partnerTitle={'10年经验大数据开发团队倾力打造，获得多家上市公司信赖'}
                        partnerImages={partnerImages}
                        type='anstar'
                    />
                </div>

                <div id='forFreeAns'>
                    <ProgramAns type='anstar' />
                </div>

            </Layout>
        );
    }
}

export default Anstar;
