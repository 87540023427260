/**
 * Created by zal on 2020/11/2
 * 解决方案主体页面
 */
import React, { useState } from 'react';
import './commonSolution.scss';
import './mobileCommonSolution.scss';
import CommonSolutionHeader from './commonSolutionHeader/CommonSolutionHeader'; // 二级头部
import CommonSchemeIntroduction from './commonDivRoot/CommonSchemeIntroduction'; // 方案介绍
import CommonOperationFlow from './commonDivRoot/CommonOperationFlow'; // 业务流程
import CommonBusinessValue from './commonDivRoot/CommonBusinessValue'; // 业务价值
import CommonApplicationCases from './commonDivRoot/CommonApplicationCases'; // 应用案例
import { isMobile } from '../../utils/util';

function CommonSolution ({ headerOnClick, data }) {
    const [isMobileValue] = useState(isMobile());
    return (
        <div
            className={`commonSolution ${ isMobileValue? 'mobileCommonSolution' : '' }`}
            style={{ minWidth: isMobileValue ? '' : '1200px' }}
        >
            <CommonSolutionHeader data={data.header} headerOnClick={headerOnClick} />
            <CommonSchemeIntroduction data={data.schemeIntroduction} />
            <CommonOperationFlow data={data.operationFlow} />
            <CommonBusinessValue data={data.businessValue} />
            <CommonApplicationCases data={data.applicationCases} />
        </div>
    );
}

export default CommonSolution;
