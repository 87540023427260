/**
 * 解决方案-区块链网络赋强公证
 */
import React, { Component } from "react";
import "./blockchainNetwork.scss";
import CommonSolution from "../../components/commonSolution";
import operationFlowPNG from "../../assets/images/solution/blockchainNetworkOperationFlow.png";
import boxCom1PNG from '../../assets/images/andChain/first.png';
import boxCom2PNG from '../../assets/images/andChain/second.png';
import boxCom3PNG from '../../assets/images/andChain/third.png';
import boxCom4PNG from '../../assets/images/andChain/forth.png';

export default class BlockchainNetwork extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                header: {
                    name: '区块链网络赋强公证',
                    describe: '具备强制执行效力的电子借贷合同',
                    buttonName: '业务咨询'
                },
                schemeIntroduction: {
                    name: '方案介绍',
                    describe: '区块链平台上游对接银行等金融机构、下游对接法院执行部门的公证办证系统，对电子借贷合同进行在线公证，并赋予强制执行效力，一方不履行合同时，由公证机构出具执行证书，批量推送至法院立案执行。从债权人向公证处申请出具执行证书之日起，经过公证处催告，出具执行证书，再到批量推送至法院立案执行，执行失败债务人进入限高人员、失信人员名单，用时可以缩小至两个月以内，且采用全网操作的模式。'
                },
                operationFlow: {
                    name: '业务流程',
                    img: operationFlowPNG
                },
                businessValue: {
                    name: '业务价值',
                    titleHeight: '2.2rem',
                    showBoxComData: [
                        { name: '证据保全', image: boxCom1PNG, content: '公证处对电子合同数据的存在或生成时间进行公证证明，证明在“什么时间存在什么内容的电子数据”，防止债务人质疑债权人收集的电子数据被篡改。' },
                        { name: '在线赋强', image: boxCom2PNG, content: '在线赋予电子借款合同债权文书公证强制执行效力，合同各方可以在线查看公证书。' },
                        { name: '公证催告', image: boxCom3PNG, content: '公证处可以代债权人通过短信、邮件、公告等方式向违约方采取一切通知手段，进行逾期款项催收，对催收的过程和事实进行证据固定。' },
                        { name: '执行对接', image: boxCom4PNG, content: '对于经催收未能回收的债权，通过系统批量出具电子执行证书，发送至对接的法院系统。法院对电子执行证书审查确认后，启动强制执行程序。' }
                    ]
                },
                applicationCases: {
                    name: '应用案例',
                    subName: '网络赋强公证平台',
                    partnerTitle: '合作方',
                    partnerText: '',
                    caseIntroductionTitle: '案例介绍',
                    caseIntroductionText: '高度自动化、便捷化的区块链网络赋强公证平台，与银行系统深度对接。从前端银行客户筛选、身份验证到中间签署电子借款合同、电子数据存证再到后期贷后催告核实、债权执行等实现自动化对接。所有业务实现在线自助办理，关键业务信息中的借款人身份认证、授权书签署、电子借款合同、银行放款凭证以及用户的还款凭证等文件数据，由区块链进行固化、加密，信息防篡改、可追溯。'
                }
            }
        };
    }

    headerOnClick =() => {
        this.props.history.push({ pathname: '/programAns/blockchainNetwork' });
    };

    render() {
        let { data } = this.state;
        return (
            <div className="blockchainNetwork">
                <CommonSolution headerOnClick={this.headerOnClick} data={data} />
            </div>
        );
    }
}
