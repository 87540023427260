/**
 * 核心产品-溯源平台
 */
import React, {Component} from "react";
import "./andTracking.scss";
import "./mobileAndTracking.scss";
import {Layout, Button} from "antd";
import supervisionSystem from '../../assets/images/andTracking/supervisionSystem.png';
import quotationMarkLeft from '../../assets/images/andTracking/quotationMarkLeft.png';
import quotationMarkRight from '../../assets/images/andTracking/quotationMarkRight.png';
import businessStructure from '../../assets/images/andTracking/businessStructure.png';
import ShowBoxCom from "../../components/showBoxCom/ShowBoxCom";
import {isMobile} from '../../utils/util';
import CommonBottomBox from "../../components/commonBottomBox/CommonBottomBox";

const {Content} = Layout;

const partnerImages = [
    require('../../assets/images/partnerBigImgs/STWH.png'),
    require('../../assets/images/partnerBigImgs/BBD.png')
];

class AndTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            DataTechnologArr: [
                {
                    name: '责任认定',
                    image: require('../../assets/images/andTracking/productValue1.png'),
                    content: '商品生产、加工、流通的每个环节的相关信息都将在链上产生完整记录，便于对商品质量问题进行责任主体认定。'
                },
                {
                    name: '快速召回',
                    image: require('../../assets/images/andTracking/productValue2.png'),
                    content: '区块链技术可以追踪到每个产品的销售路径，当产品发生质量问题时，能够实现快速精准召回。'
                },
                {
                    name: '精准营销',
                    image: require('../../assets/images/andTracking/productValue3.png'),
                    content: '通过采集产品终端销售信息，对产品的销售分布、消费者偏好展开深入分析，帮助决策者完善营销策略。'
                },
                {
                    name: '高效监管',
                    image: require('../../assets/images/andTracking/productValue4.png'),
                    content: '监管机构通过在区块链中架设节点，能够实时查看全量数据，实现真正的穿透式监管。'
                }
            ]
        };
    }

    headerOnClick = () => {
        this.props.history.push({pathname: '/programAns/andTracking'});
    };

    render() {
        const {DataTechnologArr, isMobile} = this.state;
        return (
            <Layout className={`andTracking ${isMobile ? 'mobileAndTracking' : ''}`}
                    style={{minWidth: isMobile ? '' : '1200px'}}>
                <div className={'andTrackingHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'andTrackingName'}>{'AndTracking溯源平台'}</div>
                        <div className={'andTrackingDescribe'}>记录商品的全生命周期，以完善的溯源体系保障品质安全可信</div>
                        {
                            isMobile ? ''
                                :
                                <Button style={{height: '.41rem'}} type="primary" onClick={
                                    () => window.open('http://trace.andlinks.com')
                                }>立即体验</Button>
                        }

                        <Button className={'consultButton'} onClick={this.headerOnClick}
                                style={{height: '.41rem'}}>业务咨询</Button>
                    </div>
                </div>
                <Content className="andTracking-content">
                    <div className="firstSection">
                        <div className="headerTitle">
                            全流程穿透式品质监管体系
                        </div>
                        <div className="supervisionSystem">
                            <img src={supervisionSystem} className={'supervisionSystem'} alt=""/>
                            <div className="describe">
                                <img className="quotationMarkLeft" src={quotationMarkLeft} alt=""/>
                                <div
                                    className="text">AndTracking溯源平台对商品生产、加工、批发、零售的全供应链流程进行记录和存证，实现对商品供应链上每个流转环节的追溯查询，不仅可以准确获取商品的来源信息，还可以对商品的销售分布展开分析。利用区块链技术分布式存储、不可篡改的特性，实现来源可查、去向可追、责任可究、风险可控的穿透式质量管理体系，提升企业的质量管理能力，建立良好的供应链生态体系，充分满足监管合规需求。
                                </div>
                                <img className="quotationMarkRight" src={quotationMarkRight} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="secondSection">
                        <div className="headerTitle">
                            业务架构
                        </div>
                        <img src={businessStructure} alt="" className="businessStructure"/>
                    </div>
                    <div className="threeSection">
                        <div className="headerTitle">
                            产品价值
                        </div>
                        <ShowBoxCom showData={DataTechnologArr} titleHeight={'200px'}/>
                    </div>
                </Content>
                <CommonBottomBox partnerTitle={'记录商品的全生命周期，以完善的溯源体系保障品质安全可信'}
                                 partnerImages={partnerImages}/>
            </Layout>
        );
    }
}

export default AndTracking;
