import { Menu } from 'antd';
import React from 'react';
export const HttpMethod = {
    GET: 'get',
    POST: 'post'
};
export const MenuTab = [
    {
        id: 1,
        title: '用戶管理',
        url: '/main/adminManager',
        active: true
    },
    {
        id: 2,
        title: '角色權限管理',
        url: '/main/adminManager/roleManage',
        active: false
    },
    {
        id: 3,
        title: '日誌管理',
        url: '/main/adminManager/logManage',
        active: false
    },
    {
        id: 4,
        title: '公告管理',
        url: '/main/adminManager/noticeManage',
        active: false
    }
];

// 部分权限详细

export  function getPartPermission(name) {
    if (name === '项目查询') {
        return (
            <Menu>
                <Menu.Item>一带一路项目统计</Menu.Item>
                <Menu.Item>一带一路项目分类查询</Menu.Item>
                <Menu.Item>基本信息</Menu.Item>
                <Menu.Item>项目新闻</Menu.Item>
                <Menu.Item>项目地图定位</Menu.Item>
                <Menu.Item>收藏关注(5个项目)</Menu.Item>
                <Menu.Item>1份/月项目报告</Menu.Item>
            </Menu>
        );

    }
    if (name === '地图可视化') {
        return (
            <Menu>
                <Menu.Item>项目定位</Menu.Item>
                <Menu.Item>分类查询条件设置</Menu.Item>
                <Menu.Item>项目简介（名称、地点）</Menu.Item>
            </Menu>
        );
    }

    if (name === '企业查询') {
        return (
            <Menu>
                <Menu.Item>基本信息</Menu.Item>
                <Menu.Item>经营信息</Menu.Item>
                <Menu.Item>司法信息</Menu.Item>
                <Menu.Item>一度关联图谱</Menu.Item>
                <Menu.Item>2份/月基础信息报告</Menu.Item>
                <Menu.Item>收藏关注(5个项目)</Menu.Item>
            </Menu>
        );
    }

    if (name === '数据分析') {
        return (
            <Menu>
                <Menu.Item>按维度查看分析图表</Menu.Item>
                <Menu.Item>10份/月免费分析报告</Menu.Item>
            </Menu>
        );
    }
}