/**
 * 公共底部
 */
import React, { Component, Fragment } from 'react';
import './bottom.scss';
import './mobileBottom.scss';
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils/util';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import bottomLogo from '../../assets/images/common/logo@2x.png';
import qrCodePNG from '../../assets/images/common/qrCode.png';

class bottom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baseInfo: {
                address: '南京市雨花台区软件大道106号2栋602室',
                phone: '025-83370906',
                email: 'marketing@andlinks.com',
                qrCode: qrCodePNG,
                recordNo: '苏ICP备17010247号'
            },
            menuData: [
                { name: '公司介绍', url: '/about' },
                { name: '新闻中心', url: '/news' },
                { name: '加入我们', url: '/joinus' }
            ],
            subMenuData: [
                {
                    name: '核心产品',
                    children: [
                        { name: 'AndChain区块链底层平台', url: '/andChain' },
                        { name: 'AndLogistics物流管理平台', url: '/andLogistics' },
                        { name: 'AndFinance供应链金融平台', url: '/andFinance' },
                        { name: 'AndTracking溯源平台', url: '/andTracking' },
                        { name: 'Anstar企业行为安全感知平台', url: '/anstar' },
                        { name: 'Compass智能图分析平台', url: '/compass' }
                    ]
                },
                {
                    name: '解决方案',
                    children: [
                        { name: '区块链存证', url: '/blockchainCertificate' },
                        { name: '区块链数字版权', url: '/blockchainCopyright' },
                        { name: '区块链电子证照', url: '/blockchainLicense' },
                        { name: '区块链网络赋强公证', url: '/blockchainNetwork' }
                    ]
                }
            ],
            subMenuKey: ''
        };
    }

    // 渲染手机端底部
    renderMobileBottom = () => {
        let { subMenuData, subMenuKey, baseInfo, menuData } = this.state;
        return <div className={'mobileBottom'}>
            <Link to={'/'}><img className="mobileBottomLogo" src={bottomLogo} alt={''} /></Link>
            <div className={'mobileBottomMenuRoot'}>
                {
                    menuData.map((item, index) => {
                        return <Fragment key={item.name}>
                            <Link to={item.url}>
                                <div className="mobileBottomMenuItem">{item.name}</div>
                            </Link>
                            {(index + 1) !== menuData.length ? '|' : ''}
                        </Fragment>
                    })
                }
            </div>
            {
                subMenuData.map((item, index) => {
                    let isActive = subMenuKey === index;
                    return <div key={item.name} className={'mobileBottomSubMenuRoot'}>
                        <div
                            onClick={() => { this.setState({ subMenuKey: isActive ? '' : index }); }}
                            className={'mobileBottomSubMenuTitle'}
                        >
                            <span>{item.name}</span>
                            {isActive ? <MinusOutlined /> : <PlusOutlined />}
                        </div>
                        <div className={`mobileBottomSubMenu ${isActive ? 'mobileBottomSubMenuActive' : ''}`}>
                            {
                                item.children.map(childrenItem => {
                                    return <Link to={childrenItem.url} key={childrenItem.name}>
                                        <div key={childrenItem.name} className={'mobileBottomSubMenuItem'}>{childrenItem.name}</div>
                                    </Link>;
                                })
                            }
                        </div>
                    </div>
                })
            }
            <div className={'mobileBottomBottom'}>
                <div className={'mobileBottomBottomLeft'}>
                    <img src={baseInfo.qrCode} alt={''} />
                    <span>扫码关注公众号</span>
                </div>
                <div className={'mobileBottomBottomRight'}>
                    <span><span>地址：</span><span>{baseInfo.address}</span></span>
                    <span><span>电话：</span><span>{baseInfo.phone}</span></span>
                    <span><span>邮箱：</span><span>{baseInfo.email}</span></span>
                </div>
            </div>
            <div className={'mobileRecordNo'}>{baseInfo.recordNo}</div>
        </div>;
    };

    render() {
        let { subMenuData, menuData, baseInfo } = this.state;
        return (
            isMobile() ? this.renderMobileBottom() :
                <footer className="rc-footer rc-footer-dark">
                    <section className="rc-footer-container">
                        <section className="rc-footer-columns">
                            <div className="rc-footer-column">
                                <img className="footer-logo" src={bottomLogo} alt={''} />
                                <div className="footer-box">
                                    {
                                        menuData.map(item => {
                                            return <Link to={item.url} key={item.name}>
                                                <div className="rc-footer-item title">{item.name}</div>
                                            </Link>
                                        })
                                    }
                                </div>
                            </div>
                            {
                                subMenuData.map(item => {
                                    return <div className="rc-footer-column" key={item.name}>
                                        <div className="footer-item-title">{item.name}</div>
                                        <div className="footer-item-boder" />
                                        <div className="footer-box">
                                            {item.children.map(childrenItem => {
                                                return <div className="rc-footer-item" key={childrenItem.name}>
                                                    <Link to={childrenItem.url}>
                                                        <div className="rc-footer-item-link">{childrenItem.name}</div>
                                                    </Link>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                })
                            }
                            <div className="rc-footer-column">
                                <div className="footer-item-title">联系方式</div>
                                <div className="footer-item-boder" />
                                <div className="footer-box">
                                    <div className="rc-footer-item">地址：{baseInfo.address}</div>
                                    <div className="rc-footer-item">电话：{baseInfo.phone}</div>
                                    <div className="rc-footer-item">邮箱：{baseInfo.email}</div>
                                </div>
                            </div>
                            <div className="rc-footer-column">
                                <div className="rc-footer-item">
                                    <div className="footer-item-title">更多</div>
                                    <div className="footer-item-boder" />
                                    <div className="footer-box">
                                        <img src={baseInfo.qrCode} alt={''} />
                                    </div>
                                    <div className="rc-footer-item">扫码关注公众号</div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section className="rc-footer-bottom">
                        <div className="rc-footer-bottom-container">©2020. 南京安链数据科技有限公司
                            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style={{ marginLeft: '20px' }}>{baseInfo.recordNo}</a></div>
                    </section>
                </footer>
        );
    }
}

export default bottom;
