import React, { Component } from 'react';
import arrow from '../../assets/images/anstar/arrow.png';
import "./commonBehavior.scss";
import "./mobileCommonBehavior.scss";
import { isMobile } from '../../utils/util';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

class CommonBehavior extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    render() {
        let { hebaviorData, titleHeight } = this.props;
        let { isMobile } = this.state;
        return (
            <div
                className={`commonBehavior ${isMobile ? 'mobileCommonBehavior' : ''}`}
                style={{ minWidth: isMobile ? '' : '1160px' }}>
                {
                    hebaviorData && hebaviorData.map((item, index) => {
                        return <div className="behaviorBox"
                            style={{ width: isMobile ? 'calc(100% - 0.8rem)' : `calc((100% - 210px) / 4)` }}
                        >
                            <div className="behaviorContent"
                                // style={{ height: titleHeight ? titleHeight : '2rem' }}
                            >
                                <img src={require(`../../assets/images/anstar/anstar${index+1}.png`)} className="topImg"></img>
                                <div className="titleName">{item.name}</div>
                                <div className="line"></div>
                                <div className="titleContent">
                                    {item.title}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        );
    }
}

export default CommonBehavior;