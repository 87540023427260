// 下方合作伙伴公共样式
import React, { Component } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import "./commonBottomBox.scss";
import "./mobileCommonBottomBox.scss";
import { isMobile } from "../../utils/util";
import { Carousel } from 'antd';
import { Children } from "react";

class BottomBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            imagesArr: [],
            now: 1,
            PageSize: 3,
            countArr: []
        };
    }

    componentDidMount() {
        this.getImages();
    }

    getImages = () => {
        let { partnerImages } = this.props;

        let imagesArr = [];
        let arr = [];
        partnerImages.map((item, index) => {
            arr.push(item);
            if ((index + 1) % 3 === 0) {
                imagesArr.push(arr);
                arr = [];
            }
        })

        if (arr.length > 0) {
            imagesArr.push(arr);
            arr = [];
        }

        this.setState({
            imagesArr,

        })
    }

    toOtherPage = () => {
        let pathName = window.location.pathname === '/' ? 'home' : window.location.pathname;
        window.location.href = `/programAns${pathName}`
    };

    render() {
        let { imagesArr, currentPage, countArr, isMobile, liCount } = this.state;
        let { partnerTitle, partnerImages, type } = this.props;
        return (
            <div
                className={`commonBottomContent ${isMobile ? 'mobileCommonBottomBox' : ''}`}
                style={{ minWidth: isMobile ? '' : '1160px' }}>
                <div className="commonBannarContent">
                    <div className="topBigTitle">
                        合作伙伴
                    </div>

                    <div className="imagesContent"
                        style={{ height: isMobile && liCount > 3 ? '6rem' : 'auto' }}>
                        {
                            imagesArr.length > 1 ?
                                <LeftCircleOutlined
                                    className="circleOutlined left"
                                    onClick={() => this.refs.carousel.prev()}
                                /> : ''
                        }
                        <Carousel
                            ref="carousel"
                        >
                            {
                                imagesArr && imagesArr.length && imagesArr.map((item, index) => {
                                    return <div key={item}>
                                        <div className="carouselContent">
                                            {
                                                item.map((childItem) => {
                                                    return <img key={childItem} src={childItem} className="imageStyle" />
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </Carousel>

                        {
                            imagesArr.length > 1 ?
                                <RightCircleOutlined
                                    className="circleOutlined right"
                                    onClick={() => this.refs.carousel.next()}
                                /> : ''
                        }

                    </div>

                    {
                        type === 'anstar' ? ''
                            :
                            <div className="bottomContentInfo">
                                <div className="infoContent">
                                    <div className="bottomContentTitle">
                                        {partnerTitle ? partnerTitle : ''}
                                    </div>
                                    <div className="bottomContentSubTitle">
                                        联系我们 即可启程
                            </div>
                                </div>

                                <div className="zixun"
                                    onClick={this.toOtherPage.bind(this)}
                                >
                                    立即咨询
                        </div>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default BottomBox;
