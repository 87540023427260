/**
 * Created by zal on 2020/11/2
 * 解决方案-方案介绍
 * @param  data
 * { name: String, describe: String }
 */
import React from 'react';
import './commonDivRoot.scss';

function CommonSchemeIntroduction ({ data }) {
    return (
        <div className={'commonDivRoot'}>
            <div className={'commonDiv'}>
                <div className={'commonTitle'}>{data?.name || '方案介绍'}</div>
                <div className={'commonDescribe'}>{data?.describe || ''}</div>
            </div>
        </div>
    );
}

export default CommonSchemeIntroduction;
