import React, { Component } from "react";
import "./joinus.scss";
import './mobileJoinus.scss';
import { Layout } from "antd";
import { isMobile } from '../../utils/util';
import RecruitmentTabs from './recruitmentTabs/RecruitmentTabs';
const { Content } = Layout;
class Joinus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: isMobile(),
            height: 0,
            headerClass: '',
            tab: '1'
        }
        this.windowOnScroll();
    }

    windowOnScroll() {
        let _this = this;
        window.onscroll = function () {
            let h = document.documentElement.scrollTop;
            _this.setState({
                height: h,
            });
            if (h > 430) {
                _this.setState({
                    headerClass: "header-fixed",
                })
            } else {
                _this.setState({
                    headerClass: "",
                })
            }
        }
    }

    render() {
        let { isMobile } = this.state;
        return (
            <Layout className={`joinus ${isMobile ? 'mobileJoinus' : ''}`}>
                {/* 头部*/}
                <div className={'joinusSubHeader'}>
                    <div className={'subHeaderRoot'}>
                        <div className={'compassProductName'}>加入我们</div>
                        <div className={'compassProductDescribe'}>安链科技期待和你一起创造更多可能</div>
                    </div>
                </div>
                <Content className="site-layout box-content" style={{ margin: '25px auto' }}>
                    <div className="joinus-intr">
                        <div>更好的工作，更快乐的生活</div>
                        <p>
                            在ANDLINKS，我们希望每个人都快乐、高效地工作与生活,，在大家的共同努力下，打造一支高效、拼搏、有理想、有抱负的团队。只要你有足够的能力、足够的自信，有一颗真真正正想做一番事业的决心，ANDLINKS会给你足够的施展空间。
                        </p>
                        <p>
                            你可以更加专注的面对电脑，激情无限又沉着冷静的思考，也可以在累了、休息时尽情放松。工作就是为了更好的生活，我们会竭力为团队提供舒适的办公环境，更大的自由空间，更有活力的工作氛围。我们期待着你为公司的发展做出巨大的贡献，我们更希望你能从公司的发展中享受胜利的果实，为了我们自己的明天，快马加鞭，奋勇直前。
                        </p>
                    </div>
                    <div className="joinus-rec">
                        <div className="jobInfoTitle">职位信息</div>
                        {
                            !isMobile &&
                            <div className="box">
                                <div className="left">
                                    <RecruitmentTabs />
                                </div>
                                <div className="right">
                                    <div className="right-top">
                                        <div className="right-border"></div>
                                        <div className="right-title">简历投递</div>
                                    </div>
                                    <div className="right-content">
                                        <div>邮箱</div>
                                        <p>gutingting@andlinks.com</p>
                                        <div>电话</div>
                                        <p>025-83370906</p>
                                        <div>地址</div>
                                        <p>南京市雨花台区软件大道106号2栋602室</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            isMobile && <div className="mobileBox">
                                <RecruitmentTabs />
                            </div>
                        }
                        {
                            isMobile && <div className="mobileInfo">
                                <div className="infoBox">
                                    <h2 className="title">简历投递</h2>
                                    <div>邮箱</div>
                                    <p>gutingting@andlinks.com</p>
                                    <div>电话</div>
                                    <p>025-83370906</p>
                                    <div>地址</div>
                                    <p>南京市雨花台区软件大道106号2栋602室</p>
                                </div>
                            </div>
                        }
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default Joinus;
