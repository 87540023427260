import React from 'react';
import Router from './router/router'
import "antd/dist/antd.css";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
