import { HJPromise } from "../utils/hjPromise";
//新闻列表
export function getNewsList(data) {
    return HJPromise({
        url: '/news/list',
        type: 'post',
        data
    });
}
//新闻详情
export function getNewsDetails(data) {
    return HJPromise({
        url: '/news/detail',
        type: 'post',
        data
    });
}

//首页新闻
export function getNews(data) {
    return HJPromise({
        url: '/news/homepage',
        type: 'post',
        data
    });
}

