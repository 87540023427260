const config = {
    server: {
        api: '/',
        // baseURL: ''
        baseURL: '/oa'
    },
    HttpMethod: {
        GET: 'get',
        POST: 'post'
    },
    timeout: 1000000,
    systemTipTime: 3,
    ContentType: {
        JSON: 'application/json',
        FORM_URLENCODED: 'application/x-www-form-urlencoded'
    }
};
export default config;