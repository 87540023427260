import React, { Component } from 'react';
import {isMobile} from '../../utils/util';
import "./highBoxCom.scss";
import "./mobileHighBoxCom.scss";
import * as antdMobile from 'antd-mobile';
import asItemNamePNG from "../../assets/images/compass/asItemName.png";

const AntdMobileCarousel = antdMobile.Carousel;

class HighBoxCom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile()
        };
    }

    renderHighBoxItem = () => {
        let { showData } = this.props;
        return showData.map(item => {
            return  <div className="scenariosBox" key={item.name} >
                <img src={item.bg} className="bgImg"></img>
                <img src={item.image} className="topImg"></img>
                {/*<div className="blueLeft"></div>*/}
                {/*<div className="titleName">{item.name}</div>*/}
                {/*<div className="blueRight"></div>*/}
                <div className={'highBoxComItemName'}>
                    <div className="blueLeft" />
                    <div className={'titleName'}>{item.name}</div>
                    <div className={'blueRight'} />
                </div>
                <div className="mask" >
                    <div className="show">
                        {item.content}
                    </div>
                </div>
            </div>
        });
    };

    render() {
        let { showData } = this.props;
        let { isMobile } = this.state;
        return (
            <div
                className={`highBoxCom ${isMobile ? 'mobileHighBoxCom' : ''}`}
                style={{minWidth: isMobile ? '' : '1160px'}}>
                {

                    isMobile ?
                        <AntdMobileCarousel
                            dots={true}
                            frameOverflow="visible"
                            cellSpacing={20}
                            slideWidth={0.8}
                        >
                            {this.renderHighBoxItem()}
                        </AntdMobileCarousel> :
                        this.renderHighBoxItem()

                }
            </div>
    );
    }
}

export default HighBoxCom;