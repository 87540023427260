/**
 * Created by zal on 2020/10/30
 * 丰富的应用场景
 * data: [{ name: '名称', icon: '图标', activeIcon: '选中图标', img: '下方显示图片', synopsis: '简介', describe: '描述' }]
 */
import React, { Component, Fragment } from 'react';
import './richApplicationScenarios.scss';
import './mobileRichApplicationScenarios.scss';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import applicationScenariosWavePointPNG from '../../assets/images/applicationScenarios/applicationScenariosWavePoint.png';
import { isMobile } from '../../utils/util';

class RichApplicationScenarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            isMobile: isMobile(),
            mobileActiveIndex: ''
        };
    }

    /**
     * 切换应用场景数据
     * @param activeIndex
     */
    changeActive =(activeIndex) => {
        this.setState({ activeIndex });
    };

    render() {
        let { data, name = '丰富的应用场景', borderRadius = '10px' } = this.props;
        let { activeIndex, isMobile, mobileActiveIndex } = this.state;
        let activeData = data[activeIndex];
        return (
            <div className={`richApplicationScenarios ${isMobile ? 'mobileRichApplicationScenarios' : ''}`}>
                {
                    isMobile ? <div className={'mobileRasRoot'}>
                            <div className={'rasHeaderTitle'}>{name}</div>
                            {
                                data.map((item, index) => {
                                    return <div key={item.name} className={'mobileRas'}>
                                        <div
                                            onClick={() => {this.setState({ mobileActiveIndex: mobileActiveIndex === index ? '' : index });}}
                                            className={`mobileRasItem ${mobileActiveIndex === index ? 'mobileRasItemActive' : ''}`}>
                                            <div className={'mobileRasItemActiveBg1'}/>
                                            <div className={'mobileRasItemActiveBg2'}/>
                                            {item?.icon ?
                                                <img className={'mobileRasItemIcon'} src={index === activeIndex ?  item?.activeIcon : item?.icon} alt=""/> : null}
                                            <span>{item.name}</span>
                                            { mobileActiveIndex === index ? <MinusOutlined /> : <PlusOutlined /> }
                                        </div>
                                        <div
                                            className={'mobileRasItemBottom'}
                                            style={{
                                                height: mobileActiveIndex === index ? 'auto' : 0,
                                                padding: mobileActiveIndex === index ? '' : '0'
                                            }}
                                        >
                                            <div className={'mobileRasItemBottomName'}>{ item?.name || '' }</div>
                                            <div className={'mobileRasItemBottomSynopsis'}>{ item?.synopsis || '' }</div>
                                            <div className={'mobileRasItemBottomLeftSplit'} />
                                            <div className={'mobileRasItemBottomDescribe'}>{ item?.describe || '' }</div>
                                            <img src={item?.img || null} alt=""/>
                                        </div>
                                    </div>;
                                })
                            }
                        </div> :
                        <Fragment>
                            <div className={'rasHeaderRoot'}>
                                <div className={'rasHeader'}>
                                    <div className={'rasHeaderTitle'}>{name}</div>
                                    <div className={'rasHeaderItemRoot'}>
                                        {
                                            data.map((item, index) => {
                                                return <div className={'rasHeaderItem'} key={item.name}>
                                                    <div
                                                        onClick={this.changeActive.bind(this, index)}
                                                        style={{ backgroundColor: index === activeIndex ? '#347EFF' : '' }}
                                                        className={'rasHeaderItemImgRoot'}>
                                                        {item?.icon ?
                                                            <img src={index === activeIndex ?  item?.activeIcon : item?.icon} alt=""/> : null}
                                                    </div>
                                                    <div
                                                        onClick={this.changeActive.bind(this, index)}
                                                        style={{ color: index === activeIndex ? '#1890FF' : '' }}
                                                        className={'rasHeaderItemName'}>{item.name}</div>
                                                    <div
                                                        style={{ opacity: index === activeIndex ? 1 : 0 }}
                                                        className={'rasHeaderItemActive'} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'rasBottomRoot'}>
                                <div className={'rasBottom'}>
                                    <div className={'rasBottomLeft'}>
                                        <div className={'rasBottomName'}>{ activeData?.name || '' }</div>
                                        <div className={'rasBottomSynopsis'}>{ activeData?.synopsis || '' }</div>
                                        <div className={'rasBottomLeftSplit'} />
                                        <div className={'rasBottomDescribe'}>{ activeData?.describe || '' }</div>
                                    </div>
                                    <div className={'rasBottomRight'} style={{ borderRadius: borderRadius }}>
                                        <img src={activeData?.img || null} alt=""/>
                                    </div>
                                    <img className={'rasWavePoint'} src={applicationScenariosWavePointPNG} alt=""/>
                                </div>
                            </div>
                        </Fragment>
                }
            </div>
        );
    }
}

export default RichApplicationScenarios;
